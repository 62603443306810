import { connect, useDispatch } from "react-redux";
import { getComposicionByFilter } from "../../../actions/cuentacorriente";
import { useEffect, useState } from "react";
import TableCustom from "../../../common/components/table/table";
import { COMPOSICION_COLUMN } from "./interface/columnData";
import IFilterComposicion from "./interface/IFilterComposicion";
import Filter from "./filter";

const Composicion = (props: any) => {
    const dispatch = useDispatch();
    const localStorage = JSON.parse(window.localStorage.getItem("LocalSession") || '{}');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [filter, setFilter] = useState<IFilterComposicion>({
        clienteId: localStorage.clientId.trim(),
        documento: '',
        page: 0,
        rowPerPage: 5
    })
    const getComposicion = (page: number, rowsPerPage: number): void => {
        filter.rowPerPage= rowsPerPage;
        filter.page= page;
        dispatch(getComposicionByFilter(filter));
    };
    const getFilterComposicion = ():void => {
        setPage(0);
        setRowsPerPage(5);
        filter.rowPerPage= 5;
        filter.page= 0;
        dispatch(getComposicionByFilter(filter));
    }
    useEffect(() => {
        dispatch(getComposicionByFilter(filter));
    }, []);

    useEffect(() => {
        console.log(props.composicion)
        // if (props.estadopedidos.data) {
        //     setAcopios(props.acopios?.data.$values);
        // }
    }, [props]);


    return (
        <div style={{ margin: "2vw" }}>
            <Filter 
                getFilterComposicion={getFilterComposicion}
                filter={filter}
                onSelectedFilter={setFilter}
                />
            <TableCustom page={page} 
                    onSelectedPage={setPage} 
                    rowsPerPage={rowsPerPage} 
                    onSelectedRowPerPage={setRowsPerPage} 
                    items={props.composicion?.composicionCtaCteResponses}
                    length={props.composicion?.length} 
                    getItems={getComposicion}
                    columns={COMPOSICION_COLUMN}
                />
        </div>
    );
};
export default connect(
    (state: any) => ({
        composicion: state.cuentacorriente.composicion,
        error: state.cuentacorriente.error,    
        loading: state.cuentacorriente.loading
    }),
    (dispatch) => ({
        getComposicionByFilter: (filter: IFilterComposicion) => dispatch(getComposicionByFilter(filter))
    })
)(Composicion);
