import { HashRouter } from 'react-router-dom';
import Footer from './footer'
import Pace from '../pages/pace';
import Header from './header'
import Router from './Router';
import BtnWhatsapp from './btnWhatsapp';
import { isAllowed } from '../shared/utils/permissions';

function Aplicacion() {
  return (
    <HashRouter>
      <Pace color="#0ab682" />
      <Header />      
      <Router />
      { isAllowed(19) ? <BtnWhatsapp/> : null }
      <Footer />
    </HashRouter>
  );
}

export default Aplicacion;