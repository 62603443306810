import IColumnData from "../../../../common/interface/IColumnData";

export const COMPOSICION_COLUMN :IColumnData[] = [    
    {
        width: 120,
        label: 'NRO. DOCUMENTO',
        dataKey: 'documento',
    },
    {
        width: 80,
        label: 'TIPO DE DOCUMENTO',
        dataKey: 'tipo'
    },
    {
        width: 20,
        label: 'FECHA DE EMISIÓN',
        dataKey: 'fechaEmision'
    },
    {
        width: 40,
        label: 'IMPORTE',
        dataKey: 'importe',
        numeric: true,
    }
];