import axios from 'axios';
import  TokenService  from './token';

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/Suggested/`


export default class SuggestedService {

    static async fetch() {
        const respuesta = await axios.get(`${ENDPOINT}`)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values
    }

    static async add(suggested) {
        const respuesta = await axios.post(`${ENDPOINT}`, suggested)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values
    }

    static async delete({idProducto, idProductoRelacionado}) {
        const respuesta = await axios.delete(`${ENDPOINT}${idProducto}/${idProductoRelacionado}`)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values
    }

    static async search(search) {
        console.log(search);
        const respuesta = await axios.post(`${ENDPOINT}search`, search)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values
    }
}