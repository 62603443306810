import { connect } from "react-redux";
import { Box, Paper, Table, TableBody, TableHead, TableCell, TableContainer, TableRow, TablePagination } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import IColumnData from "../../interface/IColumnData";
import './table.css';

const useStyles = makeStyles({
    table: {
        minWidth: 750,
    },
    paper: {
        width: '100%', 
        mb: 2 
    }
});

interface ITableCustomProps {
    page:number;
    onSelectedPage: (page: number)=> void;
    rowsPerPage:number;
    onSelectedRowPerPage: (rowsPerPage: number)=> void;
    items: any[];
    length: number;
    getItems: (page: number, rowsPerPage: number)=> void;
    columns: IColumnData[]; 
}

const TableCustom = (props: ITableCustomProps) => {
    const classes = useStyles();
    const handleChangePage = (event: unknown, newPage: number) => {
        props.onSelectedPage(newPage);
        props.getItems(newPage, props.rowsPerPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onSelectedRowPerPage(parseInt(event.target.value, 10));
        props.onSelectedPage(0);
        props.getItems(0, parseInt(event.target.value, 10));
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <TableHead className="backgroundColor-grey">
                            <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    className={classes.table}
                                >
                                {props.columns.map((column) => (
                                    <TableCell className="fontWeight-bold" align="center">{column.label}</TableCell> 
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            props.items?.map((row: any)=> (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    className={classes.table}
                                >
                                    {props.columns.map((column) => (
                                        <TableCell align="center">{row[column.dataKey]}</TableCell> 
                                    ))}
                                </TableRow>
                        ))}                                            
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    className="backgroundColor-grey-header"
                    count={props.length}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};
export default connect()(TableCustom);
