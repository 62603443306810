import { useState } from "react";
import { Button } from "@material-ui/core";
import colores from "../../shared/constants/styles";
import { connect } from "react-redux";
import { requestDownloadFile } from "../../actions/presupuesto";
import EditarIcono from "../../assets/icons/editar.svg";
import Tooltip from "@mui/material/Tooltip";
import "./presupuesto.css";
import PedidoDetalle from "./pedido_detalle";

const Pedido = (props) => {
  const { data } = props;
  const myUser = JSON.parse(window.localStorage.getItem("LocalSession"));
  const [popUp, setPopUp] = useState(false);
  const handleClick = () => {
    setPopUp(!popUp);
  };

  return (
    <>
      {popUp && (
        <>
          <PedidoDetalle presupuestoDetalle={data} onSetPopUp={handleClick}></PedidoDetalle>
        </>
      )}

      <div
        className="bordesRedondos col-12 col-sm-7 col-md-5 col-lg-4"
        style={{ margin: "1.875rem 0" }}
      >
        <div
          className="bordesRedondosTop white-color p-4 text-uppercase"
          style={{ backgroundColor: colores.azulOscuro }}
        >
          <div>
            <div>
              <div className="atributoTitle">
                <h4 style={{ fontWeight: "lighter" }}>
                  NRO DE SOLICITUD: <strong>{`${data?.id}`}</strong>
                </h4>
              </div>
              <div className="atributoTitle">
                <h4 style={{ fontWeight: "lighter" }}>
                  PRESUPUESTO:{" "}
                  <strong>{`${
                    data?.presupuestoTotvsId == null
                      ? "-"
                      : data?.presupuestoTotvsId
                  }`}</strong>
                </h4>
              </div>
              <div className="atributoTitle">
                <h4 style={{ fontWeight: "lighter" }}>
                  PEDIDO:{" "}
                  <strong>{`${
                    data?.pedidoId == null ? "-" : data?.pedidoId
                  }`}</strong>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: colores.grisClaro }}
          className="bordesRedondosBottom p-4"
        >
          <div className="d-flex flex-row bd-highlight">
            <div
              className="mb-3"
              style={{ fontWeight: "bold", marginRight: "1.25rem" }}
            >
              <h3 style={{ fontWeight: "bold" }} className="atributoDato">
                FECHA:{" "}
              </h3>
              {myUser.tipoRol !== 3 && (
                <h3 style={{ fontWeight: "bold" }} className="atributoDato">
                  CLIENTE:
                </h3>
              )}
              <h3 style={{ fontWeight: "bold" }} className="atributoDato">
                VENDEDOR:
              </h3>
            </div>
            <div className="mb-3">
              <h3 className="atributoDato">{data?.fecha}</h3>
              {myUser.tipoRol !== 3 && (
                <h3 className="atributoDato">
                  {data?.nombreCliente ? data?.nombreCliente : ""}
                </h3>
              )}
              <h3 className="atributoDato">{data?.nombreVendedor}</h3>
            </div>
          </div>

          <div>
            <hr className="hr-pedidos"></hr>
          </div>
          <div className="d-flex flex-row bd-highlight  justify-content-between">
            <div className="mb-6">
              <div
                className="atributoTitle"
                style={{ fontWeight: "bold", marginRight: "1.25rem" }}
              >
                TOTAL $:{" "}
              </div>
              <div className="atributoDato">{`${
                data?.moneda.simbolo
              } ${data?.montoTotal.toFixed(2)}`}</div>
            </div>
            <div className="mb-6">
              <div
                className="atributoTitle"
                style={{ fontWeight: "bold", marginRight: "1.25rem" }}
              >
                TOTAL KG:{" "}
              </div>
              <div className="atributoDato">{`${data?.pesoTotal.toFixed(
                2
              )} Kg`}</div>
            </div>
          </div>
          <div>
            <hr className="hr-pedidos"></hr>
          </div>
          <div className="d-flex flex-row bd-highlight  justify-content-between">
            <div className="mb-5">
              <div
                className="atributoTitle"
                style={{ fontWeight: "bold", marginRight: "1.25rem" }}
              >
                ESTADO
              </div>
              <div className="d-flex flex-row bd-highlight  justify-content-between">
                <div className="atributoDato" style={{ marginRight: "0.8rem" }}>
                  {data?.estadoPresupuesto}
                </div>
                <div
                  style={{
                    backgroundColor: "#4FD0FC",
                    borderRadius: "50%",
                    width: "1em",
                    height: "1em",
                    alignSelf: "center",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row bd-highlight  justify-content-between">
            <div className="mb-6">
              <Button
                className="my-3"
                style={{ backgroundColor: colores.maincolor, color: "#fff" }}
                variant="contained"
                onClick={() => handleClick()}
                type="button"
              >
                VER DETALLES
              </Button>
            </div>
            {data?.tieneReclamo ? (
              <Tooltip title="Reclamo cargado">
                <div className="mb-6">
                  <div className="fondo-circular my-3">
                    <img
                      src={EditarIcono}
                      className="card-img-top icono-svg"
                      alt="Iniciar reclamo"
                    />
                  </div>
                </div>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default connect(
  (state) => ({
    file: state.presupuestos.file,
    successDownload: state.presupuestos.successDownload,
  }),
  (dispatch) => ({
    requestDownloadFile: (numero, ruta) => dispatch(requestDownloadFile(numero, ruta)),
  })
)(Pedido);