export const Types = {
  PRODUCTO_LOADING: "PRODUCTO_LOADING",
  PRODUCTOS_FETCH_REQUESTED: "PRODUCTOS_FETCH_REQUESTED",
  PRODUCTOS_FETCH_SUCCEEDED: "PRODUCTOS_FETCH_SUCCEEDED",
  PRODUCTOS_FETCH_ERROR: "PRODUCTOS_FETCH_ERROR",
  PRODUCTOS_FETCH_GRUPO_REQUESTED: "PRODUCTOS_FETCH_GRUPO_REQUESTED",
  PRODUCTOS_FETCH_GRUPO_SUCCEEDED: "PRODUCTOS_FETCH_GRUPO_SUCCEEDED",
  PRODUCTOS_FETCH_GRUPO_ERROR: "PRODUCTOS_FETCH_GRUPO_ERROR",
  PRODUCTOS_FIND_REQUESTED: "PRODUCTOS_FIND_REQUESTED",
  PRODUCTOS_FIND_SUCCEEDED: "PRODUCTOS_FIND_SUCCEEDED",
  PRODUCTOS_FIND_ERROR: "PRODUCTOS_FIND_ERROR",
  PRODUCTOS_SEARCH_REQUESTED: "PRODUCTOS_SEARCH_REQUESTED",
  PRODUCTOS_SEARCH_SUCCEEDED: "PRODUCTOS_SEARCH_SUCCEEDED",
  PRODUCTOS_SEARCH_ERROR: "PRODUCTOS_SEARCH_ERROR",
  PRODUCTOS_CLOSE_DOWNLOAD: "PRODUCTOS_CLOSE_DOWNLOAD",
  PRODUCTOS_DOWNLOAD_REQUESTED: "PRODUCTOS_DOWNLOAD_REQUESTED",
  PRODUCTOS_DOWNLOAD_SUCCEEDED: "PRODUCTOS_DOWNLOAD_SUCCEEDED",
  PRODUCTOS_DOWNLOAD_ERROR: "PRODUCTOS_DOWNLOAD_ERROR",
  PRODUCTOS_SEARCH_ALL_REQUESTED: "PRODUCTOS_SEARCH_ALL_REQUESTED",
  PRODUCTOS_SEARCH_ALL_SUCCEEDED: "PRODUCTOS_SEARCH_ALL_SUCCEEDED",
  PRODUCTOS_SEARCH_ALL_ERROR: "PRODUCTOS_SEARCH_ALL_ERROR",

  PRODUCTOS_CONSULTAR_DISPONIBILIDAD_REQUESTED:
    "PRODUCTOS_CONSULTAR_DISPONIBILIDAD_REQUESTED",
  PRODUCTOS_CONSULTAR_DISPONIBILIDAD_SUCCEEDED:
    "PRODUCTOS_CONSULTAR_DISPONIBILIDAD_SUCCEEDED",
  PRODUCTOS_CONSULTAR_DISPONIBILIDAD_ERROR:
    "PRODUCTOS_CONSULTAR_DISPONIBILIDAD_ERROR",
};

export const startLoading = () => ({ type: Types.PRODUCTO_LOADING });

export const requestProductos = () => ({
  type: Types.PRODUCTOS_FETCH_REQUESTED,
});
export const receiveProductos = (productos) => ({
  type: Types.PRODUCTOS_FETCH_SUCCEEDED,
  productos,
});
export const receiveProductosError = (err) => ({
  type: Types.PRODUCTOS_FETCH_ERROR,
  err,
});

export const requestProductosPorGrupo = (id, clientId) => ({
  type: Types.PRODUCTOS_FETCH_GRUPO_REQUESTED,
  id,
  clientId,
});
export const receiveProductosPorGrupo = (productos) => ({
  type: Types.PRODUCTOS_FETCH_GRUPO_SUCCEEDED,
  productos,
});
export const receiveProductosPorGrupoError = (err) => ({
  type: Types.PRODUCTOS_FETCH_GRUPO_ERROR,
  err,
});

export const requestProducto = (id, clienteId) => ({
  type: Types.PRODUCTOS_FIND_REQUESTED,
  id,
  clienteId,
});
export const receiveProductoSucceeded = (producto) => ({
  type: Types.PRODUCTOS_FIND_SUCCEEDED,
  producto,
});
export const receiveProductoError = (err) => ({
  type: Types.PRODUCTOS_FIND_ERROR,
  err,
});

export const requestProductosSearch = (id, search) => ({
  type: Types.PRODUCTOS_SEARCH_REQUESTED,
  id,
  search,
});
export const receiveProductosSearchSucceeded = (productos) => ({
  type: Types.PRODUCTOS_SEARCH_SUCCEEDED,
  productos,
});
export const receiveProductosSearchError = (err) => ({
  type: Types.PRODUCTOS_SEARCH_ERROR,
  err,
});

export const requestProductosAllSearch = (search, clienteId) => ({
  type: Types.PRODUCTOS_SEARCH_ALL_REQUESTED,
  search,
  clienteId,
});
export const receiveProductosAllSearchSucceeded = (productos) => ({
  type: Types.PRODUCTOS_SEARCH_ALL_SUCCEEDED,
  productos,
});
export const receiveProductosAllSearchError = (err) => ({
  type: Types.PRODUCTOS_SEARCH_ALL_ERROR,
  err,
});

export const requestDownloadFile = (grupoId) => ({
  type: Types.PRODUCTOS_DOWNLOAD_REQUESTED,
  grupoId,
});
export const receiveDownloadFile = (file) => ({
  type: Types.PRODUCTOS_DOWNLOAD_SUCCEEDED,
  file,
});
export const receiveDownloadFileError = (err) => ({
  type: Types.PRODUCTOS_DOWNLOAD_ERROR,
  err,
});
export const closeDownloadFile = () => ({
  type: Types.PRODUCTOS_CLOSE_DOWNLOAD,
});

export const requestConsultarDisponibilidad = (id, clienteId, cantidad) => ({
  type: Types.PRODUCTOS_CONSULTAR_DISPONIBILIDAD_REQUESTED,
  id,
  clienteId,
  cantidad,
});
export const receiveConsultarDisponibilidad = (enviado) => ({
  type: Types.PRODUCTOS_CONSULTAR_DISPONIBILIDAD_SUCCEEDED,
  enviado,
});
export const receiveConsultarDisponibilidadError = (err) => ({
  type: Types.PRODUCTOS_CONSULTAR_DISPONIBILIDAD_ERROR,
  err,
});
