import { connect } from "react-redux";
import EstadoDetalle from "./estado_detalle";
import TrackingDetalle from "./tracking_detalle";
import ComprobantesDetalle from "./comprobantes_detalle";
import EstadoPedidoDetalle from "./estado_pedido_detalle";

interface IPedidoDetalleProps {
    presupuestoDetalle: any;
    onSetPopUp: () => void;
}

const PedidoDetalle = (props: IPedidoDetalleProps) => {

    return (
        <div>
            <div
                style={{
                    height: "100%",
                    width: "100%",
                    position: "fixed",
                    left: 0,
                    top: 0,
                    zIndex: "1",
                    backgroundColor: "black",
                    filter: "alpha(opacity=75)",
                    opacity: 0.75,
                }}> 
            </div>
                <div
                    className="container"
                    style={{
                        position: "fixed",
                        left: "50%",
                        top: "25%",
                        transform: "translate(-50%, -25%)",
                        bottom: "0",
                        zIndex: "2",
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}>
                <EstadoDetalle presupuestoDetalle={props.presupuestoDetalle} onSetPopUp={props.onSetPopUp}></EstadoDetalle>
                <TrackingDetalle trackings={props.presupuestoDetalle.trackings}></TrackingDetalle>
                <EstadoPedidoDetalle presupuestoId={props.presupuestoDetalle?.id}></EstadoPedidoDetalle>
                <ComprobantesDetalle comprobantes={props.presupuestoDetalle.comprobantes} 
                    puedeCargarReclamo={props.presupuestoDetalle?.puedeCargarReclamo} 
                    presupuestoId={props.presupuestoDetalle?.id}>
                </ComprobantesDetalle>
            </div>
        </div>
    );
}

export default connect()(PedidoDetalle);