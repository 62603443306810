import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { requestUltimosPresupuestos } from "../../actions/presupuesto";
import { requestKilos } from "../../actions/params";
import Dolar from "../../routes/dolar";
import Pedido from "../misPresupuestos/pedido";
import ImagenCabecera from "../../routes/imagenCabecera";
import { Container } from "react-bootstrap";
import { Grid, Button } from "@material-ui/core";
import rutas from "../../routes/constants/routes";
import MyUserService from "../../services/myUser";
import { isAllowed } from "../../shared/utils/permissions";
import "../../../node_modules/react-vis/dist/style.css";
import { es } from "date-fns/locale";
import {
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  VerticalBarSeries,
  FlexibleXYPlot,
} from "react-vis";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import "dayjs/locale/es";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import dayjs from "dayjs";

import { makeStyles } from "@material-ui/core";

const styles = makeStyles(() => ({
  multipleSelect: {
    "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
      backgroundColor: "rgb(227, 0, 11);",
      color: "white",
    },
  },
}));

const Dashboard = (props) => {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [category, setCategory] = useState([]);
  const [dateFrom, setDateFrom] = useState(dayjs(moment()));
  const [dateTo, setDateTo] = useState(dayjs(moment()));
  const [errorUI, seterrorUI] = useState({
    category: "",
    date: "",
  });

  const handleDateFromChange = (newDate) => {
    setDateFrom(newDate);
  };

  const handleDateToChange = (newDate) => {
    setDateTo(newDate);
  };

  const myUser = MyUserService.getMyUser();
  const menu = myUser?.menus?.$values ?? null;
  const menuHijo = menu?.filter((m) => m.menuPadreId !== null) ?? null;
  const clienteSeleccionado = JSON.parse(
    window.localStorage.getItem("selectedClient")
  );
  useEffect(() => {
    if (isAllowed(15) && isAllowed(20)) {
      dispatch(requestUltimosPresupuestos(myUser?.userId));
      dispatch(requestKilos(myUser?.clientId, dateFrom, dateTo, category));
    }
  }, []);

  const getKilosRequest = () => {
    if (isAllowed(15) && isAllowed(20)) {
      dispatch(requestKilos(myUser?.clientId, dateFrom, dateTo, category));
    }
  };

  const handleUpdateDashboard = () => {
    if (!handleValidation()) {
      getKilosRequest();
    }
  };

  const handleValidation = () => {
    let newErrors = {
      category: "",
      date: "",
    };
    let haveError = false;
    if (moment(dateFrom).isBefore(dateTo)) {
      newErrors = {
        ...newErrors,
        date: "Intervalo incorrecto; la fecha de inicio no puede ser mayor a la fecha de finalización",
      };
      haveError = true;
    }
    // if (dateTo.diff(dateFrom, "months") < 1) {
    //   newErrors = {
    //     ...newErrors,
    //     date: "El intervalo debe ser mayor a 2 meses",
    //   };
    //   haveError = true;
    // }

    if (category.length === 0) {
      newErrors = {
        ...newErrors,
        category: "Debe elegir al menos una sub-categoria",
      };
      haveError = true;
    }

    if (dateTo.diff(dateFrom, "months") > 12) {
      newErrors = {
        ...newErrors,
        date: "El intervalo no debe ser mayor a 12 meses",
      };
      haveError = true;
    }

    seterrorUI(newErrors);
    return haveError;
  };

  const handleChangeCategory = ({ target }) => {
    setCategory(target.value);
  };

  const { ultimosPresupuestos, loading, gruposProductos } = props;
  const pedidos = ultimosPresupuestos;

  const colores = {
    1: "#01B8AA",
    2: "#374649",
    3: "#3599B8",
    4: "#C39FBB",
    5: "#FFC0CB",
    6: "#A52A2A",
    7: "#32a852",
    8: "#3285a8",
    9: "#3c32a8",
    10: "#a83295",
    11: "#a83248",
    12: "#3b040e",
    13: "#e0b12d",
    14: "#e0e02d",
    15: "#abe02d",
    16: "#2de0a2",
    17: "#E3000B",
    18: "#E3000B",
    19: "#E3000B",
    20: "#E3000B"
  };

  let respuesta = [];

  const coloresCategorias = [];

  const handleClick = () => {
    history.push(`${rutas.MIS_PRESUPUESTOS}`);
  };

  return (
    <>
      <ImagenCabecera> </ImagenCabecera>
      <Dolar> </Dolar>

      <div className="container">
        <div className="justify-content-center">
          {isAllowed(20) && (
            <Container>
              <Grid className="row">
                <div
                  className="d-flex justify-content-between flex-wrap align-items-center"
                  style={{
                    marginBottom: "0.600rem",
                    paddingRight: "3rem",
                    paddingLeft: "21px",
                  }}
                >
                  <h1 className="fw-bolder" style={{ margin: "1rem 0" }}>
                    MIS ÚLTIMAS OPERACIONES
                  </h1>
                </div>
              </Grid>

              {loading && !Array.isArray(pedidos) ? (
                <p className="text-center" style={{ fontSize: "1.5rem" }}>
                  Cargando Operaciones...
                </p>
              ) : (
                <>
                  <Grid container alignItems="start">
                    <Grid item xs={12}>
                      <Grid className="row">
                        {
                          <Grid container className="d-block-sm">
                            {pedidos?.map((pedido) => (
                              <Pedido key={pedido.id} data={pedido} />
                            ))}
                          </Grid>
                        }
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 5,
                        }}
                      >
                        {pedidos?.length > 3 && (
                          <Button
                            variant="outlined"
                            style={{
                              borderColor: "black",
                              borderRadius: "0.75rem",
                              fontSize: "1rem",
                              fontWeight: "bold",
                              width: "12rem",
                              marginTop: "1.75rem",
                            }}
                            onClick={() => handleClick()}
                          >
                            <span>
                              Ver Más <b>Operaciones</b>
                            </span>
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </Container>
          )}
        </div>
      </div>

      {(
        myUser?.clientId
          ? myUser?.clientId
          : clienteSeleccionado
          ? clienteSeleccionado[clienteSeleccionado.length - 1]?.id
          : null
      ) ? (
        <>
          <div className="d-flex justify-content-center">
            <hr style={{ width: "91.6%", color: "black", marginTop: "0" }}></hr>
          </div>
          <Container>
            <Grid className="row">
              <div
                className="d-flex justify-content-between flex-wrap align-items-center"
                style={{
                  marginBottom: "0.600rem",
                  paddingRight: "3rem",
                  paddingLeft: "21px",
                }}
              >

              </div>
            </Grid>
          </Container>
          <Container>
            <div
              style={{
                marginBottom: "0.600rem",
                paddingRight: "3rem",
                paddingLeft: "21px",
              }}
            >
        
            
            </div>
          </Container>

         
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default connect(
  (state) => ({
    ultimosPresupuestos: state.presupuestos.ultimosPresupuestos,
    loading: state.presupuestos.loading,
    kilos: state.params.kilos,
    gruposProductos: state.params.grupos,
  }),
  (dispatch) => ({
    requestUltimosPresupuestos: (userId) =>
      dispatch(requestUltimosPresupuestos(userId)),
    requestKilos: (clientId) => dispatch(requestKilos(clientId)),
  })
)(Dashboard);
