import { useEffect } from "react";
import { connect } from "react-redux";
import { requestCliente } from "../../actions/cliente";
import { Grid } from "@material-ui/core";
import colores from '../../shared/constants/styles';
import Dolar from '../../routes/dolar';
import rutas from "../../routes/constants/routes";
import { useHistory } from "react-router";
import ListadoDescuentos from "../home/descuentos";
import VendedorCard from "./vendedorCard";
import Tienda from "./tienda";
import { makeStyles } from "@material-ui/core";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

const useStyles = makeStyles((theme) => ({
  PDato: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: "2vh"
  },
  PDatoCliente: {
    fontSize: 18,
    fontWeight: "bold",
    color: "gray",
    margin: "0px"
  },
  OficialBack:{
    backgroundColor: "pink"
  }
}));

const Profile = (props) => {

  const style = useStyles();

  const history = useHistory();
  const { cliente, tiendas, descuentos } = props;

  const myUser = JSON.parse(window.localStorage.getItem("LocalSession"));

  const usuario = {
    ultimoIngreso: myUser?.ultimoLogin,
    mail: myUser?.emailAddress,
    Nombre: myUser?.bussinessName != null ? myUser?.bussinessName : myUser?.userName,
    numeroDoc: myUser?.numeroDoc,
    identificationId: myUser?.identificationId,
    phoneNumber: myUser?.phoneNumber,
    id: myUser?.userId,
  };

  var identificacion = "";

  switch (usuario.identificationId) {
    case 1:
      identificacion = "CUIL";
      break;
    case 2:
      identificacion = "DNI";
      break;
    case 3:
      identificacion = "CUIT";
      break;
    default:
      break;
  }

  useEffect(() => {

    const local = JSON.parse(window.localStorage.getItem("LocalSession"));
    props.requestCliente(local.userId);
  }, []);

  const handleEdit = () => {
    history.push(`${rutas.CHANGE_PASSWORD}`);
  };

  return (
    <>
    <div style={{ backgroundColor: colores.grisClaro, overflow: "hidden", clear: "both" }}>
      <Dolar></Dolar>

      <div className='d-flex justify-content-center'>
          <hr style={{ width: "90%", color: "black" }}></hr>
      </div>
      <div className='d-flex justify-content-between flex-wrap align-items-center' style={{ marginBottom: "0.600rem", paddingRight: "3rem", paddingLeft: "21px" }}>
          <h1 className="fw-bolder" style={{ margin: "1rem 3.5rem" }}>MI PERFIL</h1>
      </div>
      
    </div>
      {
        props.cliente.cliente === 0
        ?
        <div className="container" style={{marginBottom: "5rem"}}>
          <div className="row d-flex flex-wrap">
            <p style={{ fontSize: "2rem", margin: "4rem 0rem" }}>PERFIL DE VENDEDOR</p>
            <div  className="col-sm-4 d-flex flex-nowrap" >
              <div>
                    <p className={style.PDato} style={{ marginRight: "1.25rem"}}>N° de usuario </p>
                    <p className={style.PDato} style={{ marginRight: "1.25rem"}}>Tipo de documento </p>
              </div>
              <div>
                  <p className={style.PDatoCliente}>{props.cliente.vendedorProfile?.idVendedor}</p>
                  <p className={style.PDatoCliente}>{identificacion}</p> 
              </div>
            </div>
            <div className="col-sm-4  d-flex flex-nowrap">
              <div >
                    <p className={style.PDato} style={{ marginRight: "1.25rem"}}>Número </p>
                    <p className={style.PDato} style={{ marginRight: "1.25rem"}}>Nombre </p>
              </div>
              <div>
                  <p className={style.PDatoCliente}>{props.cliente.vendedorProfile?.nroDocumento}</p>
                  <p className={style.PDatoCliente}>{props.cliente.vendedorProfile?.apellidoNombre}</p>
              </div>
            </div>
            <div  className="col-sm-4  d-flex flex-nowrap">
              <div >
                    <p className={style.PDato} style={{ marginRight: "1.25rem"}}>Correo </p>
                    <p className={style.PDato} style={{ marginRight: "1.25rem"}}>Telefono </p>
              </div>
              <div>
                  <p className={style.PDatoCliente}>{props.cliente.vendedorProfile?.email}</p>
                  <p className={style.PDatoCliente}>{props.cliente.vendedorProfile?.telefono}</p>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="container">
          <div style={{ marginTop:"5rem" }}>
            <div className="d-flex align-content-center flex-wrap"></div>
            <p style={{ fontSize: "2.1875rem", fontWeight: "bold"}}>Mis Datos</p>
            <div className="row">
              <div className="col-sm-2" style={{margin: "4vh"}}>
                <div style={{ borderRadius: "50%", width: "5vw", height: "5vw", backgroundColor: colores.azulOscuro}}>
                  <PersonOutlineOutlinedIcon style={{fill: "white", width: "3vw", height: "3vw", margin: "1vw"}}/>  
                </div>              
              </div>
              <div class="col-sm-1" style={{ borderLeft: "2px solid", borderLeftColor: colores.azulOscuro, height: "200px"}}></div>

              <div className="col-sm-4" >    
                <p className={style.PDatoCliente}>N° de usuario: </p>
                <p className={style.PDato}>{usuario?.id}</p>
                <p className={style.PDatoCliente}>Tipo de documento: </p>
                <p className={style.PDato}>{usuario?.numeroDoc}</p>
                <p className={style.PDatoCliente}>N°: </p>
                <p className={style.PDato}>{identificacion}</p>
              </div>
              <div className="col-sm-4">
                <p className={style.PDatoCliente}>Nombre: </p>
                <p className={style.PDato}>{usuario?.Nombre}</p>
                <p className={style.PDatoCliente}>Email: </p>
                <p className={style.PDato}>{usuario?.mail}</p>
                <p className={style.PDatoCliente}>Teléfono: </p>
                <p className={style.PDato}>{usuario?.phoneNumber}</p>
              </div>
            </div>
          
          <p style={{ fontSize: "2.1875rem", fontWeight: "bold"}}>Vendedores</p>
          <div className="d-flex flex-wrap">
              <Grid container spacing={5} className="d-flex">
                  <VendedorCard title={"Oficial de cuenta"} 
                    nombre={props.cliente.oficialDeCuenta?.apellidoNombre} 
                    email={props.cliente.oficialDeCuenta?.email} 
                    telefono={props.cliente.oficialDeCuenta?.telefono} />
                  { props.cliente.vendedoresStaff?.$values.length >=0 && 
                    (
                      props.cliente.vendedoresStaff?.$values?.map(staff => (
                            <VendedorCard title={"Vendedores Staff"} 
                              nombre={staff.apellidoNombre} 
                              email={staff.email} 
                              telefono={staff.telefono} />
                      ))
                    )}
                </Grid>
        </div>
        <div className="container">
            <p style={{ fontSize: "2.1875rem", fontWeight: "bold"}}>Tiendas</p>
            <div className="d-flex flex-wrap">
              <Grid container spacing={5} className="d-flex">
                  <Tienda key={props.cliente.tiendaUno?.tiendaNro} data={props.cliente?.tiendaUno} />
                  {props.cliente.tiendas?.$values.map((tienda) => (
                    <Tienda key={tienda.tiendaNro} data={tienda} />
                  ))}
                </Grid>
            </div>
        </div>

            <div className="container">
              <p style={{ fontSize: "2.1875rem", fontWeight: "bold" }}>Descuentos</p>
              <div style={{marginBottom: "1.5rem"}}>
                <ListadoDescuentos key={cliente.id} title={"Descuento comercial"} data={props.cliente.reglaDescuento?.$values} />
              </div>
              <div style={{marginBottom: "1.5rem"}}>
                <ListadoDescuentos key={cliente.id} title={"Descuento por familia de productos"} data={props.cliente.reglaDescuentoDetalle?.$values} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default connect(
  (state) => ({
    cliente: state.clientes.cliente,
    loading: state.clientes.loading,
  }),
  (dispatch) => ({
    requestCliente: (id) => dispatch(requestCliente(id)),
  })
)(Profile);
