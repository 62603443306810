import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ThemeProvider } from "@mui/material";
import muiStyles from "../../shared/constants/muiStyles";
import { TextField } from "@material-ui/core";

const MuiCustomInput = (props) => {
  const classes = muiStyles.muiInputGeneralStyles(props)();
  const component = props.component;
  let res;
  switch (component) {
    case "FormControl":
      res = (
        <FormControl
          className={classes.root}
          style={{
            marginInlineEnd: "7rem",
            minWidth: "15rem",
            border: "2px solid #B4B5B8",
            borderRadius: "0px",
          }}
          hiddenLabel={true}
        >
          {/* <InputLabel variant='filled' disableAnimation={false} id="demo-simple-select-label">Productos</InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Categoria"
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={(e) => props.handleChange(e.target.value)}
          >
            <MenuItem value=''>
                TODOS LOS PRODUCTOS
              </MenuItem>
            {props.gruposDropdown?.map((grupo) => (
              <MenuItem key={grupo?.gruposProdId} value={grupo?.gruposProdId}>
                {grupo?.gruposDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
      break;
    case "TextField":
      res = (
        <TextField
          id="standard-basic"
          className={classes.textInput}
          label="Descripción"
          onChange={(e) => props.handleSearch(e.target.value)}
          value={props.value}
          InputProps={{ disableUnderline: true }}
          style={{
            width: "30rem",
            borderBottom: "1px solid #B4B5B8",
            paddingLeft: "-2px",
            paddingBottom: "0px",
            marginBottom: "5px",
          }}
        />
      );
      break;
    default:
      res = null;
  }
  return (
    <Box sx={{ minWidth: 120 }}>
      <ThemeProvider theme={muiStyles.muiGeneralTheme(props?.flagForm)}>
        {res}
      </ThemeProvider>
    </Box>
  );
};
export default MuiCustomInput;
