import { connect, useDispatch } from "react-redux";
import { getEstadoPedidoByFilter } from "../../../actions/cuentacorriente";
import { useEffect, useState } from "react";
import { ESTADO_PEDIDOS_COLUMN } from "./interface/columnData";
import cuentaCorrienteService  from "../../../services/cuentacorriente";
import Filter from "./filter";
import TableCustom from "../../../common/components/table/table";
import IFilterEstadoPedido from "./interface/IFilterEstadoPedido";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import IconButton from '@mui/material/IconButton';
import IClienteIdFilter from "../../../common/interface/IClienteIdFilter";

const EstadoPedidos = (props: any) => {
    const dispatch = useDispatch();
    const localStorage = JSON.parse(window.localStorage.getItem("LocalSession") || '{}');
    const [clienteFilter, setClienteFilter] = useState<IClienteIdFilter>({
        clienteId: localStorage.clientId.trim()
    })

    const [filter, setFilter] = useState<IFilterEstadoPedido>({
        dateFrom: null,
        dateTo: null,
        estadoId: null,
        presupuestoId: null,
        clienteId: localStorage.clientId.trim(),
        page: 0,
        rowPerPage: 5
    })
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const getEstadoPedido = (page: number, rowsPerPage: number): void => {
        filter.rowPerPage= rowsPerPage;
        filter.page= page;
        dispatch(getEstadoPedidoByFilter(filter));
    };
    const getFilterEstadoPedido = ():void => {
        setPage(0);
        setRowsPerPage(5);
        filter.rowPerPage= 5;
        filter.page= 0;
        dispatch(getEstadoPedidoByFilter(filter));
    }
    
    useEffect(() => {
        dispatch(getEstadoPedidoByFilter(filter));
    }, []);

    useEffect(() => {
        console.log(props.estadopedidos)
        // if (props.estadopedidos.data) {
        //     setAcopios(props.acopios?.data.$values);
        // }
    }, [props]);

    const handleDownloadd = async () => {
        const response = await cuentaCorrienteService.downloadFileEstadoPedidos(clienteFilter);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    return (
        <div style={{ margin: "2vw" }}>
            
            <div className="col-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2vw' }}>
                <div style={{ fontSize: '20px' }}>Descargar archivo</div>
                <IconButton
                    aria-label="toggle visibility"
                    style={{ fontSize: '40px' }} 
                >
                    <DownloadForOfflineIcon
                        onClick={() => handleDownloadd()}
                        style={{ fontSize: 'inherit' }} 
                    />
                </IconButton>
            </div>          

            <Filter 
                getFilterEstadoPedido={getFilterEstadoPedido}
                filter={filter}
                onSelectedFilter={setFilter}
                />
            <TableCustom page={page} 
                    onSelectedPage={setPage} 
                    rowsPerPage={rowsPerPage} 
                    onSelectedRowPerPage={setRowsPerPage} 
                    items={props.estadopedidos?.estadoPedidosResponses}
                    length={props.estadopedidos?.length} 
                    getItems={getEstadoPedido}
                    columns={ESTADO_PEDIDOS_COLUMN}
                />
        </div>
    );
};
export default connect(
    (state: any) => ({
        estadopedidos: state.cuentacorriente.estadopedidos,
        error: state.cuentacorriente.error,    
        loading: state.cuentacorriente.loading
    }),
    (dispatch) => ({
        getEstadoPedidoByFilter: (filter: IFilterEstadoPedido) => dispatch(getEstadoPedidoByFilter(filter))
    })
)(EstadoPedidos);
