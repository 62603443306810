import axios from 'axios'

const { REACT_APP_ENDPOINT_CUENTA_CORRIENTE } = process.env;
const ENDPOINT_ESTADOPEDIDOS = `${REACT_APP_ENDPOINT_CUENTA_CORRIENTE}/api/estadopedidos`
const ENDPOINT_SALDOS = `${REACT_APP_ENDPOINT_CUENTA_CORRIENTE}/api/saldo`
const ENDPOINT_COMPOSICION = `${REACT_APP_ENDPOINT_CUENTA_CORRIENTE}/api/composicion`
const ENDPOINT_FACTURA = `${REACT_APP_ENDPOINT_CUENTA_CORRIENTE}/api/factura`

export default class cuentacorriente {
    static async getByPresupuestoId(clienteId, presupuestoId) {
        const respuesta = await axios.get(`${ENDPOINT_ESTADOPEDIDOS}/GetByPresupuesto?clienteId=${clienteId}&presupuestoId=${presupuestoId}`)
        .then(response => {
            return response
        })
        return respuesta.data
    }

    static async getEstados() {
        const respuesta = await axios.get(`${ENDPOINT_ESTADOPEDIDOS}/GetEstados`)
        .then(response => {
            return response
        })
        return respuesta.data
    }

    static async getEstadoPedidosByFilter(filter) {
        const respuesta = await axios.post(`${ENDPOINT_ESTADOPEDIDOS}/GetByFilter`, filter )
        .then(response => {
            return response
        })  
        return respuesta.data
    }

    static async getSaldosByFilter(filter) {
        const respuesta = await axios.post(`${ENDPOINT_SALDOS}/GetByFilter`, filter )
        .then(response => {
            return response
        })  
        return respuesta.data
    }

    static async getComposicionByFilter(filter) {
        const respuesta = await axios.post(`${ENDPOINT_COMPOSICION}/GetByFilter`, filter )
        .then(response => {
            return response
        })  
        return respuesta.data
    }

    static async getFacturasByFilter(filter) {
        const respuesta = await axios.post(`${ENDPOINT_FACTURA}/GetByFilter`, filter,  {
            headers: { 'Content-Type': 'application/octet-stream' },
            responseType: 'blob' 
        }).then(response => {
            return response
        })  
        return respuesta.data
    }

    static async downloadFileEstadoPedidos(filter) {
        const respuesta = await axios.post(`${ENDPOINT_ESTADOPEDIDOS}/DownloadExcel`, filter)
        .then(response => {
            return response
        })  
        return respuesta.data
    }
}
