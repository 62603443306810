import {call, put, takeEvery, fork} from 'redux-saga/effects';
import TokenService from '../services/token';

import * as actions from '../actions/auth';
import auth from '../services/auth';

function* login({loginData}) {
    yield put(actions.startLoading());
    try {
        const res = yield call(auth.login, loginData);
        if(res){
            TokenService.setToken(res.tokens.accessToken);
            yield put(actions.loginSucceeded());
        }
    } catch (error) {
        yield put(actions.loginError(error));
        console.log(error)
    }
}

function* ResetPassword({data}) {
    yield put(actions.startLoading());
    console.log(data)
    try {
        const res = yield call(auth.resetPassword, data);
        yield put(actions.receiveResetPasswordSucceeded(res));
    } catch (err) {
        yield put(actions.receiveResetPasswordError(err));
    }
}

function* ChangePasswordEmail({data}) {
    yield put(actions.startLoading());
    try {
        const res = yield call(auth.changePasswordEmail, data);
        if(res){
            TokenService.clear();
            yield put(actions.changePasswordEmailSucceeded(true, true));
        }
    } catch (err) {
        yield put(actions.changePasswordEmailError(err));
        console.log(err)
    }
}

function* ChangePassword({data}) {
    yield put(actions.startLoading());
    try {
        const res = yield call(auth.changePassword, data);
        if(res){
            TokenService.clear();
            yield put(actions.changePasswordSucceeded(true, true));
        }
    } catch (err) {
        console.log(err)
    }
}

function*  watchLoginRequest() {
    yield takeEvery(actions.Types.AUTH_OATH_REQUEST, login);
}

function*  watchResetPasswordRequest() {
    yield takeEvery(actions.Types.AUTH_RESET_PASSWORD_REQUESTED, ResetPassword);
}

function*  watchChangePasswordRequest() {
    yield takeEvery(actions.Types.AUTH_CHANGEPASSWORD_REQUEST, ChangePassword);
}

function*  watchChangePasswordEmailRequest() {
    yield takeEvery(actions.Types.AUTH_RESET_PASSWORDEMAIL_REQUESTED, ChangePasswordEmail);
}

const authSagas = [
    fork(watchLoginRequest),
    fork(watchChangePasswordRequest),
    fork(watchResetPasswordRequest),
    fork(watchChangePasswordEmailRequest)
]

export default authSagas;