const colores = {
    rojo: '#E3000B',
    maincolor: '#0ab682',
    grisOscuro: '#2b323c',
    grisClaro: '#EAEBF0',
    azulOscuro: '#022A3A',
    celeste: '#4FD0FC',
    activo: '#39FF88',
    inactivo: '#F3E72C',
    rechazado: '#E22022',
    white: '#FFF'
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ...colores
}