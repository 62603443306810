import { connect, useDispatch } from "react-redux";
import { getSaldosByFilter } from "../../../actions/cuentacorriente";
import { useEffect, useState } from "react";
import CardSaldo from "./card_saldo";
import IFilterSaldo from "./interface/IFilterSaldos";
import { SALDO_COLUMN } from "./interface/columnData";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Card, CardHeader } from "@mui/material";

const Saldos = (props: any) => {
    const dispatch = useDispatch();
    const localStorage = JSON.parse(window.localStorage.getItem("LocalSession") || '{}');
    const [filter, setFilter] = useState<IFilterSaldo>({
        clienteId: localStorage.clientId.trim()
    })

    useEffect(() => {
        dispatch(getSaldosByFilter(filter));
    }, []);

    useEffect(() => {
        console.log(props.saldos)
        // if (props.estadopedidos.data) {
        //     setAcopios(props.acopios?.data.$values);
        // }
    }, [props]);

    return (
        <div style={{ margin: "2vh 0vh 2vh 15vh" }}>
            <div className="row">
                <div className="col-md-2">
                    <CardSaldo title={"SALDO 15 DÍAS"} data={props.saldos?.saldo15Dias}></CardSaldo>      
                </div>
                <div className="col-md-2">
                    <CardSaldo title={"SALDO E/ 15 y 30 DÍAS"} data={props.saldos?.saldo1530Dias}></CardSaldo>      
                </div>
                <div className="col-md-2">
                    <CardSaldo title={"SALDO E/ 30 y 45 DÍAS"} data={props.saldos?.saldo3045Dias}></CardSaldo>      
                </div>
                <div className="col-md-2">
                    <CardSaldo title={"SALDO + 45 DÍAS  "} data={props.saldos?.saldo45Dias}></CardSaldo>      
                </div>
                <div className="col-md-2">
                    <CardSaldo title={"SALDO TOTAL"} data={props.saldos?.saldoTotal}></CardSaldo>      
                </div>
            </div>
        </div>
    );
};
export default connect(
    (state: any) => ({
        saldos: state.cuentacorriente.saldos,
        error: state.cuentacorriente.error,    
        loading: state.cuentacorriente.loading
    }),
    (dispatch) => ({
        getSaldosByFilter: (filter: IFilterSaldo) => dispatch(getSaldosByFilter(filter))
    })
)(Saldos);
