import {
    Grid,
} from '@material-ui/core';
import colores from '../../shared/constants/styles';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';

const Tienda = (props) => {

    const {data} = props;

    return (
        <Grid className="bordesRedondos tienda" item xs={12} sm={6} md={4} lg={4} style={{marginBottom: "2.5rem"}}>
            <div className="bordesRedondosTop white-color p-4" style={{backgroundColor: colores.azulOscuro}}>
                <Grid container>
                    <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                        <StoreMallDirectoryOutlinedIcon style={{fill: "white", width: "4vh", height: "4vh"}}/>  
                        <h5 style={{margin: "6px"}}>{data?.nombreFantasia && data.nombreFantasia.charAt(0).toUpperCase() + data.nombreFantasia.slice(1).toLowerCase()}</h5>
                    </Grid>
                </Grid>
            </div>
            <Grid item xs={12} style={{backgroundColor: colores.grisClaro}} className="bordesRedondosBottom p-4">
                <Grid item className="atributoTitle">N°</Grid>
                <Grid item className="atributoDato">{data?.tiendaNro}</Grid>
                <Grid item className="atributoTitle">Teléfono</Grid>
                <Grid item className="atributoDato">{data?.telefono}</Grid>
                <Grid item className="atributoTitle">Dirección</Grid>
                <Grid item className="atributoDato">{data?.calle + ' ' + data?.calleNro}</Grid>
            </Grid> 
            
        </Grid>
    )
}
export default Tienda