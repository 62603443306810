import React from 'react'
import "./index.css"

function CardMessage(props) {

  return (
    <div className='CardMessage__container'>
      { props.cliente
        ? <p>No tienes productos asociados a esta categoria</p>
        : <div>
          <p>Debes elegir un cliente con productos en esta categoria</p>
          <p>Para esto debes ir a Mis Operaciones {'->'} Nuevo Presupuesto</p>
        </div>
      } 
    </div>
  )
}

export default CardMessage