import { connect, useDispatch } from "react-redux";
import { getFacturasByFilter } from "../../../actions/cuentacorriente";
import { useEffect, useState } from "react";
import TableCustom from "../../../common/components/table/table";
import { FACTURA_COLUMN } from "./interface/columnData";
import IFilterFactura from "./interface/IFilterFactura";
import Filter from "./filter";

const Factura = (props: any) => {
    const dispatch = useDispatch();
    const localStorage = JSON.parse(window.localStorage.getItem("LocalSession") || '{}');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [filter, setFilter] = useState<IFilterFactura>({
        clienteId: localStorage.clientId.trim(),
        documento: '',
        page: 0,
        rowPerPage: 5
    })

    const getFacturas = (page: number, rowsPerPage: number): void => {
        filter.rowPerPage= rowsPerPage;
        filter.page= page;
        dispatch(getFacturasByFilter(filter));
    };
    const getFilterFacturas = ():void => {
        setPage(0);
        setRowsPerPage(5);
        filter.rowPerPage= 5;
        filter.page= 0;
        dispatch(getFacturasByFilter(filter));
    }
    useEffect(() => {
        dispatch(getFacturasByFilter(filter));
    }, []);

    useEffect(() => {
        console.log(props.facturas)
        // if (props.estadopedidos.data) {
        //     setAcopios(props.acopios?.data.$values);
        // }
    }, [props]);

    return (
        <div style={{ margin: "2vw" }}>
            <Filter 
                getFilterFactura={getFilterFacturas}
                filter={filter}
                onSelectedFilter={setFilter}
                />
            <TableCustom page={page} 
                    onSelectedPage={setPage} 
                    rowsPerPage={rowsPerPage} 
                    onSelectedRowPerPage={setRowsPerPage} 
                    items={props.facturas?.facturaResponses}
                    length={props.facturas?.length} 
                    getItems={getFacturas}
                    columns={FACTURA_COLUMN}
                />
        </div>
    );
};
export default connect(
    (state: any) => ({
        facturas: state.cuentacorriente.facturas,
        error: state.cuentacorriente.error,    
        loading: state.cuentacorriente.loading
    }),
    (dispatch) => ({
        getFacturasByFilter: (filter: IFilterFactura) => dispatch(getFacturasByFilter(filter))
    })
)(Factura);
