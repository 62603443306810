import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Alert from "@mui/material/Alert";
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import colores from '../../shared/constants/styles';
import { Container } from '@material-ui/core';
import { maxWidth } from '@mui/system';
import ProductoSinImagen from '../../assets/images/ProductoSinImagen.png';
import {
    requestPreconfirmData,
    requestPreconfirmPresupuesto
} from '../../actions/presupuesto';
import { requestCliente } from "../../actions/cliente";
import MyUserService from "../../services/myUser";
import { isAllowed } from '../../shared/utils/permissions';
import Dolar from '../../routes/dolar';
import { useFormik } from 'formik';
import * as Yup from "yup";
import rutas from "../../routes/constants/routes";
import { useParams, useHistory } from "react-router-dom";

import WrapperMap from './WrapperMap';
import ViewMap from './ViewMap';
import MarkerMap from './MarkerMap';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';

//#region Estilos
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .0)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
}));
//#endregion Estilos 

const EnvioPago = (props) => {
    
    const myUser = MyUserService.getMyUser();
    var mensajeTransporte = '';
    myUser?.paramsList?.$values?.filter((e) => { if (e.id === 5) mensajeTransporte = e.texto; })
    const expirationTime = JSON.parse(window.localStorage.getItem("expirationtime"));
    const realExpirationTime = JSON.parse(window.localStorage.getItem("LocalSession")).shoopExpirationTime;
    const time= expirationTime ? expirationTime: 300;
    const realTime= realExpirationTime ? realExpirationTime: 300;
    const history = useHistory();
    const [tiempoRestante, setTiempoRestante] = useState(time); 
    var { idPresupuesto } = useParams();

    const { presupuesto, preconfirmData } = props;
    var tiendas = preconfirmData?.tiendas?.$values.sort();
    var transportes = preconfirmData?.listaTransportes?.$values;
    var clasificacionEnvios= preconfirmData?.clasificacionEnvios?.$values;
    var formasPago = preconfirmData?.listaFormasPago?.$values;
    var detalles = presupuesto?.presupuestoDetalles?.$values;
    const [errorMsj, setErrorMsj] = useState(false);

    useEffect(() => {
        if (props.successPreconfirm) {
            presupuesto?.id !== 0 && history.push(`${rutas.CARRITOCONFIRMAR}/${idPresupuesto}/${myUser?.userId}`);
        }
      }, [props.successPreconfirm]);

    // if (success) {
    //     presupuesto?.id !== 0 && history.push(`${rutas.CARRITOCONFIRMAR}/${presupuesto?.id}/${myUser?.userId}`);
    // }

    const isAvailable = (transporte) => {
        return transporte ? (transporte.clientes?.$values?.some(c => c.clienteTiendaNro === selectedDestino)) : false;
    }
    // Función para formatear el tiempo en minutos y segundos
    const formatTiempoRestante = (tiempo) => {
        const minutos = Math.floor(tiempo / 60);
        const segundos = tiempo % 60;
        return `${minutos < 10 ? '0' : ''}${minutos}:${segundos < 10 ? '0' : ''}${segundos}`;
    };

    useEffect(() => {
        const tiempoExpiracion = time;
        const temporizador = setInterval(() => {
            setTiempoRestante((prevTiempo) => {
                const nuevoTiempo = prevTiempo - 1;
                // Guarda el nuevo tiempo restante en el almacenamiento local
                localStorage.setItem('expirationtime', nuevoTiempo);
                return nuevoTiempo;
            });
        }, 1000);

        const tiempoExpiracionMillis = tiempoExpiracion * 1000;

        const redirigir = () => {
            localStorage.setItem('expirationtime', realTime);
            history.push(`${rutas.CARRITO}`); 
        };

        const expiracionTimeout = setTimeout(() => {
            redirigir();
        }, tiempoExpiracionMillis);

        const handleBeforeUnload = (event) => {
            const mensaje = '¿Estás seguro de abandonar la página? Perderás los datos de la transacción.';
            event.returnValue = mensaje; // Estándar para la mayoría de los navegadores
            return mensaje; // Necesario para algunos navegadores antiguos
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            clearInterval(temporizador);
            clearTimeout(expiracionTimeout);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [history]);

    // Inicialización de selecciones 
    const [selectedDestino, setSelectedDestino] = React.useState() 
    if (!!tiendas && selectedDestino === undefined) {
        setSelectedDestino(tiendas?.[0]?.tiendaNro);
    }
    const [selectedTelefono, setSelectedTelefono] = React.useState() //tiendas[0].id);
    const [selectedTransporte, setSelectedTransporte] = React.useState() 
    const [selectedClasificacionEnvio, setSelectedClasificacionEnvio] = React.useState() 
    if (!!transportes && selectedTransporte === undefined) {
        var transporteDefault = transportes?.find(t => isAvailable(t))
        transporteDefault?.id && setSelectedTransporte(transporteDefault?.id);
    }
    if (!!clasificacionEnvios && selectedClasificacionEnvio === undefined) {
        var clasificacionDefault = clasificacionEnvios?.find(t => isAvailable(t))
        clasificacionDefault?.id && setSelectedClasificacionEnvio(clasificacionDefault?.id);
    }
    
    const [selectedFormaPago, setSelectedFormaPago] = React.useState() //formasPago[0].id);
    const [pagoDiscount, setPagoDiscount] = useState(0);
    const [descuento, setDescuento] = React.useState(0);
    const [descuentoCliente, setDescuentoCliente] = React.useState(0);
    const [mapPopUp, setMapPopUp] = useState(false);
    const [useMap, setUseMap] = useState(false);
    const [textArea, setTextArea] = useState("");
    const [clickFinal, setClickFinal] = useState(false);

    useEffect(() => {
        props.requestPreconfirmData(idPresupuesto);
        return () => {
            localStorage.setItem('expirationtime', realTime);
        };
    }, [])

    useEffect(() => {
        const formaPagoDto = formasPago?.find(f => f.id === selectedFormaPago)?.porcentaje;
        const montoDescuento = presupuesto?.montoTotalSinDto?.toFixed(2) * formaPagoDto / 100;
        setDescuento(montoDescuento);
        if (!isNaN(montoDescuento)) {
            setDescuento(descuentoCliente + montoDescuento);
        }
    }, [selectedFormaPago, formasPago, presupuesto?.montoTotal]);

    useEffect(() => {
        const descuentosCliente = presupuesto?.descuentoCliente;
        setDescuentoCliente(descuentosCliente > 0 ? presupuesto?.montoTotalSinDto?.toFixed(2) * descuentosCliente / 100 : 0);
        const descuentosProductosCategorias = presupuesto?.presupuestoDetalles?.$values.map((descuentoDetalle) => (descuentoDetalle.descuento1 > 0 ? descuentoDetalle.montoSubtotal * descuentoDetalle.descuento1 / 100 : 0)).reduce((prev, curr) => prev + curr, 0);
        setDescuento((prev) => (prev + descuentosProductosCategorias));
    }, [props]);

    useEffect(() => {
        if (preconfirmData && preconfirmData.datosRecibe) {
            formik.setValues({
                ...formik.values,
                nombre: preconfirmData?.datosRecibe.nombre,
                apellido: preconfirmData?.datosRecibe.apellido,
                telefono: preconfirmData?.datosRecibe.telefono,
                dni: preconfirmData?.datosRecibe.nroDocumento,
            });
            setErrorMsj(false);
        } else {
            setErrorMsj(true);
        }
    }, [props.preconfirmData]);

    const redondear = (monto) => {
        return Math.round((monto + Number.EPSILON) * 100) / 100
    }
    const calculoMontoTotalSinDcto = (items) => {
        let totalAmout = 0

        if (items !== undefined) {
            for (let i = 0; i < items.length; i++) {
                totalAmout = items[i].montoSubtotal + totalAmout
            }
        } else {
            return 0
        }
        // Esta formula es para tener dos decimales de forma precisa evitando toFixed que aveces da erroes
        return redondear(totalAmout)
    }

    const calculoMontolConDcto = (total, items, user) => {
        let descuentoPago = 0
        let totalAmoutDTo = 0

        if (items !== undefined) {
            for (let i = 0; i < items.length; i++) {
                totalAmoutDTo = items[i].montoSubtotalDto + totalAmoutDTo
            }
        } else {
            return 0
        }

        if (pagoDiscount !== 0) {
            descuentoPago = (totalAmoutDTo * pagoDiscount) / 100
        }
        // Esta formula es para tener dos decimales de forma precisa evitando toFixed que aveces da erroes
        return redondear(totalAmoutDTo - descuentoPago)
    }

    const montoTotalCarrito = calculoMontoTotalSinDcto(detalles)
    const montoTotalDescuento = calculoMontolConDcto(montoTotalCarrito, detalles, presupuesto)
    const montoDescuento = redondear(montoTotalCarrito - montoTotalDescuento)

    const handleClickMap = () => {
        setMapPopUp(!mapPopUp)
    }

    const handleCloseMap= () => {
        setMapPopUp(false)
        setSelectedObra(false);
        setUseMap(false);
    }
    //Variables para el manejo del maps de google
    const [clicks, setClicks] = React.useState([]);
    const [zoom, setZoom] = React.useState(12); // initial zoom
    const [center, setCenter] = React.useState({
        lat: -34.7982878,
        lng: -58.4735295,
    });
    const [location, setLocation] = React.useState([]);
    const [selectedObra, setSelectedObra] = React.useState(false);

    const onClick = (e) => {
        setClicks([...clicks, e.latLng]);
        setCenter(() => ({
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        }));
    };

    const onIdle = (m) => {

    };

    const formik = useFormik({
        initialValues: {
            nombre: "",
            apellido: "",
            telefono: "",
            dni: "",
            clienteTiendaEntregaNro: selectedDestino,
            transporteId: selectedTransporte,
            clasificacionId: selectedClasificacionEnvio,
            condicionPagoId: selectedFormaPago,
            id: idPresupuesto,
            usuarioAlta: myUser?.userId,
            esObra: useMap,
            obraCalle: "",
            obraNro: "",
            obraCodigoPostal: "",
            obraLatitud: "",
            obraLongitud: "",
            localidad: "",
            provinciaId: "",
            TransporteDesc: textArea
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required("Ingrese el nombre de la persona que recibe").matches(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/, 'Ingrese un nombre válido').trim(),
            apellido: Yup.string().required("Ingrese el apellido de la persona que recibe").matches(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/, 'Ingrese un apellido válido').trim(),
            telefono: Yup.string().matches(/^[0-9]*$/, 'Ingrese un número válido').max(15, "Ingrese un DNI válido").test("len", 'Ingrese un número de DNI', val => val?.trim().length > 0).trim(),
            dni: Yup.string().matches(/^[0-9]*$/, 'Ingrese un número válido').max(15, "Ingrese un DNI válido").test("len", 'Ingrese un número de DNI', val => val?.trim().length > 0).trim(),
            clienteTiendaEntregaNro: Yup.string().test("type", 'Debe seleccionar una tienda', value => value !== ""),
            transporteId: Yup.string().test("type", "Debe seleccionar un transporte", value => value !== ""),
            clasificacionId: Yup.string().test("type", "Debe seleccionar un tipo de envío", value => value !== ""),
            condicionPagoId: Yup.lazy(value => {
                switch (typeof value) {
                    case 'array':
                        return Yup.array().test("type", "Debe seleccionar una una forma de pago", value => value.length > 0); // schema for object
                    case 'string':
                        return Yup.string().test("type", "Debe seleccionar una una forma de pago", value => value !== ""); // schema for string 
                    case 'null':
                        return Yup.object().test("type", "Debe seleccionar una una forma de pago", value => value.length > 0); // schema for string
                    default:
                        return Yup.mixed(); // here you can decide what is the default
                }
            }),
            esObra: Yup.bool(),
            obraCalle: Yup.string(),
            obraNro: Yup.string(),
            obraCodigoPostal: Yup.string(),
            localidad: Yup.string(),
            provinciaId: Yup.string().trim(),
            TransporteDesc: Yup.string().trim()
        }),
        onSubmit: (formData) => {
            if(selectedFormaPago != undefined){
                props.requestPreconfirmPresupuesto(formData = {
                    ...formData, condicionPagoId: formData.condicionPagoId[0],
                    esObra: useMap && selectedObra,
                    provinciaId: useMap && selectedObra ? (typeof location[4] != "undefined" ? location[4].long_name : "") : "",
                    obraCalle: useMap && selectedObra ? (typeof location[1] != "undefined" ? location[1].long_name : "") : "",
                    obraNro: useMap  && selectedObra? (typeof location[0] != "undefined" ? location[0].long_name : "") : "",
                    obraCodigoPostal: useMap  && selectedObra? (typeof location[6] != "undefined" ? location[6].long_name : "") : "",
                    localidad: useMap  && selectedObra? (typeof location[2] != "undefined" ? location[2].long_name : "") : "",
                    descuento4: descuento,
                    obraLatitud: center.lat.toString(),
                    obraLongitud: center.lng.toString(),
                    TransporteDesc: textArea
                })
            }
        }
    })
    //#region Interacciones Acordeón
    // Acordeon
    const [expanded, setExpanded] = React.useState('cliente');
    const handleChange = (panel) => (event, newExpanded) => {

        setExpanded(newExpanded ? panel : false);
        setExpanded(panel);
        if (formik.values.clienteTiendaEntregaNro === undefined) {
            formik.values.clienteTiendaEntregaNro = (tiendas?.[0]?.tiendaNro);
        }
        if (formik.values.transporteId === undefined) {
            var transporteDefault = transportes?.find(t => isAvailable(t))
            setSelectedTransporte(transporteDefault?.id);
            formik.values.transporteId = (transporteDefault?.id);
        }
        if (formik.values.clasificacionId === undefined) {
            var clasificacionDefault = clasificacionEnvios?.find(t => isAvailable(t))
            setSelectedClasificacionEnvio(clasificacionDefault?.id);
            formik.values.clasificacionId = (clasificacionDefault?.id);
        }
        if (formik.values.condicionPagoId === undefined) {
            formik.values.condicionPagoId = (formasPago?.[0]?.id);
        }
    };
    // Botones de selección de panel Destino

    const handleSelectDestino = (value) => (event) => {
        if (value !== 0) {
            setSelectedClasificacionEnvio(clasificacionDefault?.id);
            formik.values.clasificacionId= clasificacionDefault?.id;
            setSelectedObra(false);
            // setSelectedTransporte(transporteDefault?.id);
            // formik.values.transporteId = transporteDefault?.id;
        } 
        setSelectedDestino(value);
    };
    // Botones de selección de panel Transporte
    const handleSelectTransporte = (value) => (event) => {
        setSelectedTransporte(value);
    };
    // Botones de selección de panel para selección de envio
    const handleSelectClasificacionEnvio = (value) => (event) => {
        setSelectedClasificacionEnvio(value);
    };
    // Botones de selección de panel Forma de Pago
    const handleSelectFormaPago = (value, porcentaje) => (event) => {
        setSelectedFormaPago(value);
        setPagoDiscount(porcentaje)
        formik.values.condicionPagoId = value;
    };

    const handleCrearObra = (event) => {
        setUseMap(true);
        setSelectedObra(true);
        setMapPopUp(false);
    }
    const conditionalButton = () => {
        return (location[0]?.types[0] === "street_number")
    }

    const handleChangeTextArea = (event) => {
        setTextArea(event.target.value);
    }

    const handleValueMaps = (type) => {
        let valueMap=  location.filter(obj => {
            return obj.types.includes(type)
          });
        return typeof valueMap[0] != "undefined" ? type === 'administrative_area_level_1' ? valueMap[0].long_name:  valueMap[0].short_name: "";
    }

    return (
        <>
            <div style={{ overflow: "hidden", clear: "both" }}>
                <Dolar></Dolar>
                <div className='d-flex justify-content-between flex-wrap align-items-center' style={{ backgroundColor: colores.grisClaro, paddingLeft: "75px", paddingBottom: "0.600rem", paddingRight: "3rem" }}>
                    <h1 className="fw-bolder">ENVÍO Y PAGO</h1>
                    <div style={{float:'right', marginRight: '15vw'}}>
                        <h3>Tiempo restante para realizar la compra: {formatTiempoRestante(tiempoRestante)}</h3>
                    </div>
                </div>
                <Container className="py-4 px-5" component="main" maxWidth="lg">
                    <div className="row">
                        <div className='col-6'>
                            <div>
                                <form onSubmit={formik.handleSubmit} component="fieldset">
                                    {mapPopUp ?
                                        <>
                                            <div
                                                style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    position: 'fixed',
                                                    left: 0,
                                                    top: 0,
                                                    zIndex: '1',
                                                    backgroundColor: 'black',
                                                    filter: 'alpha(opacity=75)',
                                                    opacity: 0.75,
                                                }}
                                            ></div>
                                            <div className="container" style={{ position: "fixed", top: '10%', left: '10%', bottom: '0', zIndex: "2", overflowY: 'auto', overflowX: 'hidden' }}>
                                                <div class="container" style={{ width: "90%" }}>
                                                    <div style={{ backgroundColor: 'white', paddingTop: "1.20rem", paddingBottom: "1.20rem", borderRadius: '15px 15px 0 0' }}>
                                                        <button onClick={handleCloseMap} className="float-end" style={{ border: "none", backgroundColor: 'white' }}>X</button>
                                                        <h5 className="text-center" style={{ fontWeight: 'lighter' }}>REGISTRÁ LA DIRECCIÓN DE LA OBRA</h5>
                                                    </div>
                                                    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2" style={{ background: 'white', margin: 0, borderRadius: '0 0 15px 15px' }}>
                                                        <div className='col-md-4'>
                                                            <div style={{ padding: "1.25rem", margin: 0 }}>
                                                                <div className="mb-1">
                                                                    <label for="provinciaId" style={{ color: colores.grisOscuro }} className="form-label">Provincia*</label>
                                                                    <input type="text" onChange={formik.handleChange} className="form-control" id="provinciaId" name='provinciaId' value={handleValueMaps('administrative_area_level_1')} disabled />
                                                                </div>
                                                                <div className="mb-1">
                                                                    <label for="localidad" style={{ color: colores.grisOscuro }} className="form-label">Localidad*</label>
                                                                    <input type="text" onChange={formik.handleChange} className="form-control" id="localidad" name='localidad' value={handleValueMaps('locality')} disabled />
                                                                </div>
                                                                <div className="mb-1">
                                                                    <label for="obraCalle" style={{ color: colores.grisOscuro }} className="form-label">Calle*</label>
                                                                    <input type="text" onChange={formik.handleChange} className="form-control" id="obraCalle" name='obraCalle' value={handleValueMaps('route')} disabled />
                                                                </div>
                                                                <div className="mb-1">
                                                                    <label for="obraNro" style={{ color: colores.grisOscuro }} className="form-label">Nro*</label>
                                                                    <input type="text" onChange={formik.handleChange} className="form-control" id="obraNro" name='obraNro' value={handleValueMaps('street_number')} disabled />
                                                                </div>
                                                                <div className="mb-1">
                                                                    <label for="obraCodigoPostal" style={{ color: colores.grisOscuro }} className="form-label">Código postal</label>
                                                                    <input type="text" onChange={formik.handleChange} className="form-control" id="obraCodigoPostal" name='obraCodigoPostal' value={handleValueMaps('postal_code')} disabled />
                                                                </div>
                                                                <div className="col-md-12 text-center">
                                                                    <button className="btn" 
                                                                        style={{ background: colores.maincolor, color: "white", width: "50%" }} 
                                                                        disabled={typeof location[4] == "undefined" || typeof location[2] == "undefined" || typeof location[1] == "undefined" || typeof location[0] == "undefined"}
                                                                        onClick={() => handleCrearObra()} >Crear</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-8' >

                                                            <WrapperMap>
                                                                <ViewMap
                                                                    center={center}
                                                                    style={{
                                                                        height: '400px',
                                                                        width: '100%'
                                                                    }}
                                                                    onClick={onClick}
                                                                    onIdle={onIdle}
                                                                    zoom={zoom}
                                                                    location={location}
                                                                    setLocation={setLocation}
                                                                >
                                                                    <MarkerMap
                                                                        position={center}
                                                                    />
                                                                </ViewMap>
                                                            </WrapperMap>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        ""
                                    }
                                    {errorMsj && (
                                        <Alert severity="error">
                                            Lo siento, tuvimos un inconveniente. Le pedimos que se dirija al carrito nuevamente. Gracias!
                                        </Alert>
                                        )}
                                    <div>
                                        <Accordion expanded={expanded === 'cliente'}>
                                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                <Typography style={{ textTransform: 'uppercase' }}>Datos de quien recibe</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ paddingLeft: "37px", paddingRight: "37px" }}>
                                                <div className="row">
                                                    <div className="col">
                                                        <label for="nombre" className="form-label" style={{ color: "gray", fontWeight: 600, fontSize: "0.938rem" }}>Nombre</label>
                                                        <input className="form-control"
                                                            type="text"
                                                            id="nombre"
                                                            onChange={formik.handleChange}
                                                            maxLength={28}
                                                            style={{ borderColor: "black", padding: "0.625rem" }}
                                                            value={formik.values.nombre}
                                                        >
                                                        </input>
                                                        {formik.errors.nombre ? (<div className='text-danger'>{formik.errors.nombre}</div>) : null}
                                                    </div>

                                                    <div className="col">
                                                        <label for="apellido" className="form-label" style={{ color: "gray", fontWeight: 600, fontSize: "0.938rem" }}>Apellido/s</label>
                                                        <input className="form-control"
                                                            type="text"
                                                            id="apellido"
                                                            onChange={formik.handleChange}
                                                            maxLength={28}
                                                            style={{ borderColor: "black", padding: "0.625rem" }}
                                                            value={formik.values.apellido}
                                                        >
                                                        </input>
                                                        {formik.errors.apellido ? (<div className='text-danger'>{formik.errors.apellido}</div>) : null}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col">
                                                        <label for="telefono" className="form-label" style={{ color: "gray", fontWeight: 600, fontSize: "0.938rem" }}>Teléfono</label>
                                                        <input className="form-control"
                                                            type="text"
                                                            id="telefono"
                                                            onChange={formik.handleChange}
                                                            style={{ borderColor: "black", padding: "0.625rem" }}
                                                            maxLength={15}
                                                            value={formik.values.telefono.trimRight()}
                                                        >
                                                        </input>
                                                        {formik.errors.telefono ? (<div className='text-danger'>{formik.errors.telefono}</div>) : null}
                                                    </div>
                                                    <div className="col">
                                                        <label for="dni" className="form-label" style={{ color: "gray", fontWeight: 600, fontSize: "0.938rem" }}>Número de DNI</label>
                                                        <input className="form-control"
                                                            type="text"
                                                            id="dni"
                                                            onChange={formik.handleChange}
                                                            style={{ borderColor: "black", padding: "0.625rem" }}
                                                            maxLength={15}
                                                            value={formik.values.dni.trimRight()}
                                                        >
                                                        </input>
                                                        {formik.errors.dni ? (<div className='text-danger'>{formik.errors.dni}</div>) : null}
                                                    </div>
                                                </div>
                                                {Object.keys(formik.errors).length === 0 ? (
                                                    <div>
                                                        <button className="btn btn-danger float-end" type="button" 
                                                            onClick={handleChange('destino')} disabled={errorMsj}
                                                            style={{ backgroundColor: colores.maincolor, padding: "0.625rem", marginTop: "1.25rem", inlineSize: "10rem" }}>Siguiente</button>
                                                    </div>
                                                ) : null}


                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion expanded={expanded === 'destino'}>
                                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                                <Typography style={{ textTransform: 'uppercase' }}>Destino</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ paddingLeft: "37px", paddingRight: "37px" }}>
                                                <div>
                                                    <FormLabel component="legend" xs={12} style={{ color: "gray", fontWeight: 600, fontSize: "0.938rem" }}>Tiendas registradas</FormLabel>
                                                    {tiendas?.map(tienda => (
                                                        <div className="row" style={{ width: maxWidth }}>
                                                            <table class="table table-hover  table-responsive table-condensed" border="0" style={{ borderColor: "white", borderBlockStartColor: "white" }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="col">
                                                                            <Radio
                                                                                checked={selectedDestino === tienda.tiendaNro}
                                                                                onClick={handleSelectDestino(tienda.tiendaNro)}
                                                                                value={tienda.tiendaNro}
                                                                                name="clienteTiendaEntregaNro"
                                                                                onChange={formik.handleChange}
                                                                                inputProps={{ 'aria-label': tienda.tiendaNro }}
                                                                            />
                                                                        </th>
                                                                        <th scope="col">
                                                                            <span style={{ color: "gray", fontWeight: 600, fontSize: "0.900rem" }}>{tienda.nombreFantasia}</span><span style={{ color: "gray", fontWeight: 300, fontSize: "0.900rem" }}><br />{tienda.calle} {tienda.calleNro}, {tienda.provincia}{tienda.pais != null ? `, ${tienda.pais}` : ''} </span>
                                                                        </th>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ))}
                                                    {
                                                        useMap
                                                            ?
                                                            <div className="row" style={{ width: maxWidth }}>
                                                                <table class="table table-hover  table-responsive table-condensed" border="0" style={{ borderColor: "white", borderBlockStartColor: "white" }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th scope="col">
                                                                                <Radio
                                                                                    checked={selectedDestino === 0}
                                                                                    onClick={handleSelectDestino(0)}
                                                                                    value={0}
                                                                                    name="clienteTiendaEntregaNro"
                                                                                    onChange={formik.handleChange}
                                                                                    inputProps={{ 'aria-label': 0 }}
                                                                                />
                                                                            </th>
                                                                            <th scope="col">
                                                                                <span style={{ color: "gray", fontWeight: 600, fontSize: "0.900rem" }}>
                                                                                    Obra
                                                                                </span>
                                                                                <span style={{ color: "gray", fontWeight: 300, fontSize: "0.900rem" }}>
                                                                                    <br/>
                                                                                    {typeof location[1] != "undefined" ? location[1].long_name : ""}
                                                                                    {typeof location[0] != "undefined" ? " " + location[0].long_name : ""},
                                                                                    {typeof location[4] != "undefined" ? " " + location[4].long_name : ""},
                                                                                    {typeof location[3] != "undefined" ? " " + location[3].long_name : ""},
                                                                                    {typeof location[6] != "undefined" ? " " + location[6].long_name : ""}
                                                                                </span>
                                                                                {location[0]?.types[0] !== "street_number" ? (<div className='text-danger'>Dirección sin número, por favor verifique.</div>) : null}
                                                                            </th>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            :
                                                            ""
                                                    }
                                                    <div style={{ color: colores.maincolor, cursor: 'pointer' }} onClick={handleClickMap}>
                                                        + ENVIAR A DOMICILIO
                                                    </div>
                                                    <div className='float-end'>
                                                        <button className="btn btn-danger" type="button" onClick={handleChange('cliente')} style={{ backgroundColor: colores.grisOscuro, borderColor: colores.grisOscuro, padding: "0.625rem", marginTop: "1.25rem", inlineSize: "10rem" }} >Volver</button>
                                                        {formik.values.clienteTiendaEntregaNro !== "0" && <button className="btn btn-danger" type="button" onClick={handleChange('transporte')} style={{ backgroundColor: colores.maincolor, padding: "0.625rem", marginTop: "1.25rem", inlineSize: "10rem", marginLeft: "1rem" }} hidden={conditionalButton()} >Siguiente</button>}
                                                        {location[0]?.types[0] === "street_number" && <button className="btn btn-danger" type="button" onClick={handleChange('transporte')} style={{ backgroundColor: colores.maincolor, padding: "0.625rem", marginTop: "1.25rem", inlineSize: "10rem", marginLeft: "1rem" }} >Siguiente</button>}
                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion expanded={expanded === 'transporte'}>
                                            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                                <Typography style={{ textTransform: 'uppercase' }}>envío</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ paddingLeft: "37px", paddingRight: "37px" }}>
                                                <div>
                                                    {
                                                        clasificacionEnvios?.map( (clasificacion) => {
                                                            return (
                                                                <p>
                                                                    <table class="table table-hover  table-responsive table-condensed" border="0" style={{ borderColor: "white", borderBlockStartColor: "white" }}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th scope="col">
                                                                                    <Radio
                                                                                        checked={selectedClasificacionEnvio === clasificacion?.id}
                                                                                        onClick={handleSelectClasificacionEnvio(clasificacion?.id)}
                                                                                        value={clasificacion?.id}
                                                                                        name="clasificacionId"
                                                                                        onChange={formik.handleChange}
                                                                                        inputProps={{ 'aria-label': clasificacion?.id }}
                                                                                    />
                                                                                    <span style={{ color: "gray", fontWeight: 600, fontSize: "0.900rem" }}>{clasificacion?.descripcion}</span>
                                                                                </th>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </p>
                                                            )
                                                        })
                                                    }
                                                    {/* {
                                                        selectedTransporte?.trim() === 'EXP' ?
                                                            <div>
                                                                <div className="row d-flex justify-content-center" style={{ width: '100%' }}>
                                                                    <textarea placeholder={'Ingrese nuevo transporte'} value={textArea} onChange={handleChangeTextArea} />
                                                                </div>
                                                            </div>

                                                            :
                                                            ""
                                                    } */}

                                                    <div className='float-end'>
                                                        <button className="btn btn-danger" type="button" onClick={handleChange('destino')} style={{ backgroundColor: colores.grisOscuro, borderColor: colores.grisOscuro, padding: "0.625rem", marginTop: "1.25rem", inlineSize: "10rem" }} >Volver</button>
                                                        { 
                                                            clasificacionEnvios?.some(t => t.id === selectedClasificacionEnvio ? true : false) 
                                                                ? (<button className="btn btn-danger" type="button" onClick={handleChange('pago')} style={{ backgroundColor: colores.maincolor, padding: "0.625rem", marginTop: "1.25rem", inlineSize: "10rem", marginLeft: "1rem" }} >
                                                                    Siguiente
                                                                </button>)
                                                                : (<button className="btn btn-danger" type="button" style={{ backgroundColor: colores.maincolor, padding: "0.625rem", marginTop: "1.25rem", inlineSize: "10rem", marginLeft: "1rem" }} disabled>Siguiente</button>)
                                                        }
                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion expanded={expanded === 'pago'}>
                                            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                                <Typography style={{ textTransform: 'uppercase' }}>Forma de Pago</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ paddingLeft: "37px", paddingRight: "37px" }}>
                                                {formasPago?.map(formaPago => (
                                                    <div className="row" style={{ width: maxWidth }}>
                                                        <table
                                                            class="table table-hover  table-responsive table-condensed"
                                                            border="0"
                                                            style={{ borderColor: colores.grisClaro, backgroundColor: colores.grisClaro, borderRadius: "6px" }}
                                                        >
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="col">
                                                                        <Checkbox
                                                                            checked={selectedFormaPago === formaPago?.id}
                                                                            value={formaPago?.id}
                                                                            name="condicionPagoId"
                                                                            onClick={handleSelectFormaPago(formaPago?.id, formaPago?.porcentaje)}
                                                                            onChange={formik.handleChange}
                                                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }} />
                                                                    </th>
                                                                    <th scope="col">
                                                                        <span style={{ fontWeight: 600, fontSize: "0.900rem", textTransform: 'uppercase' }}>{formaPago?.descripcion}</span>
                                                                        <br />{formaPago?.porcentaje > 0 ? (
                                                                            <span style={{ fontWeight: 300, fontSize: "0.900rem", textTransform: 'uppercase' }}>Descuento financiero del {formaPago?.porcentaje}% adicional</span>
                                                                        ) : (
                                                                            <span style={{ fontWeight: 300, fontSize: "0.900rem", textTransform: 'uppercase' }}>Sin descuento financiero</span>
                                                                        )}

                                                                    </th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ))}
                                                <div className='float-end'>
                                                    <button className="btn btn-danger" type="button" onClick={ handleChange('transporte') } 
                                                        style={{ backgroundColor: colores.grisOscuro, borderColor: colores.grisOscuro, padding: "0.625rem", marginTop: "1.25rem", inlineSize: "10rem" }} >
                                                            Volver
                                                    </button>
                                                        { 
                                                        selectedFormaPago === undefined ?
                                                            (<button className="btn btn-danger" type="button" style={{ backgroundColor: colores.maincolor, padding: "0.625rem", marginTop: "1.25rem", inlineSize: "10rem", marginLeft: "1rem" }} disabled>Siguiente</button>): 
                                                            (<button className="btn btn-danger" type="submit" onClick={() => { setClickFinal(true) }} 
                                                                style={{ backgroundColor: colores.maincolor, padding: "0.625rem", marginTop: "1.25rem", inlineSize: "10rem", marginLeft: "1rem" }} >
                                                                    Siguiente
                                                            </button>)
                                                    }
 
                                                    {formik.touched.nombre && formik.errors.nombre ? (<div className='text-danger'>{formik.errors.nombre}</div>) : null}
                                                    {formik.touched.apellido && formik.errors.apellido ? (<div className='text-danger'>{formik.errors.apellido}</div>) : null}
                                                    {formik.touched.telefono && formik.errors.telefono ? (<div className='text-danger'>{formik.errors.telefono}</div>) : null}
                                                    {formik.touched.dni && formik.errors.dni ? (<div className='text-danger'>{formik.errors.dni}</div>) : null}
                                                    {formik.touched.tienda && formik.errors.tienda ? (<div className='text-danger'>{formik.errors.tienda}</div>) : null}
                                                    {formik.touched.transporte && formik.errors.transporte ? (<div className='text-danger'>{formik.errors.transporte}</div>) : null}
                                                    {formik.touched.formaPago && formik.errors.formaPago ? (<div className='text-danger'>{formik.errors.formaPago}</div>) : null}
                                                    {clickFinal && selectedFormaPago === undefined ? (<div className='text-danger'>Debes seleccionar un metodo de pago</div>) : null}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>

                                    </div>
                                </form>
                            </div>
                        </div>


                        <div className="col-6">
                            <div style={{ padding: "2rem" }}>
                                <div className="row" border="0"
                                    style={{
                                        borderColor: colores.grisClaro, backgroundColor: colores.grisClaro,
                                        borderRadius: "6px", padding: "2rem",
                                    }}
                                >
                                    <span style={{ fontWeight: 400, fontSize: "2rem" }} >Resumen</span>
                                    <div className="tablaproducts" style={{ height: "300px", overflow: "auto" }}>

                                        {detalles?.map(detalle => (

                                            <div class="row" style={{ margin: "2rem 0rem" }}>
                                                <div class="col" style={{ paddingInline: "0" }}>
                                                    {detalle?.producto?.images?.$values[0]
                                                        ?
                                                        (<img src={detalle?.producto?.images?.$values[0]} className="card-img-top" style={{ width: "4rem", border: "1px solid black", borderRadius: "7px" }} alt="Imagen del producto" />)
                                                        :
                                                        (<img src={ProductoSinImagen} className="card-img-top" style={{ width: "4rem", border: "1px solid black", borderRadius: "7px" }} alt="Producto sin imagen" />)
                                                    }
                                                </div>
                                                <div class="col-6">
                                                    <span style={{ color: "gray", fontWeight: 300, fontSize: "0.900rem" }}>{detalle?.producto?.descripcion}</span>
                                                    <span style={{ color: "gray", fontWeight: 600, fontSize: "0.900rem" }}><br />{detalle?.cantidadTiras} Unidades</span>
                                                </div>
                                                <div class="col" style={{ paddingInline: "0" }}>
                                                    <span style={{ color: "gray", fontWeight: 600, fontSize: "0.900rem" }}>{presupuesto?.moneda?.simbolo} {redondear(detalle?.cantidadTiras * detalle?.precioLista)}</span>
                                                </div>
                                            </div>

                                        ))}

                                    </div>

                                    <div>
                                        <table class="table table-hover  table-responsive table-condensed" >
                                            <tbody>
                                                <tr>
                                                    <th scope="col">
                                                        <span style={{ fontWeight: 400, fontSize: "2rem" }} >Subtotal</span>
                                                    </th>
                                                    <th scope="col" style={{ paddingInline: "0" }}>
                                                        <span style={{ fontWeight: 400, fontSize: "2rem" }} >{presupuesto?.moneda?.simbolo} </span>
                                                    </th>
                                                    <th scope="col" style={{ width: '400px', paddingInline: "0", textAlign: 'right' }}>
                                                        <span style={{ fontWeight: 400, fontSize: "2rem" }} >{montoTotalCarrito}</span>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th scope="col">
                                                        <span style={{ fontWeight: 400, fontSize: "2rem" }} >Descuentos</span>
                                                    </th>
                                                    <th scope="col" style={{ paddingInline: "0" }}>
                                                        <span style={{ fontWeight: 400, fontSize: "2rem" }} >{presupuesto?.moneda?.simbolo} </span>
                                                    </th>
                                                    <th scope="col" style={{ paddingInline: "0", textAlign: 'right' }}>
                                                        <span style={{ fontWeight: 400, fontSize: "2rem" }} >{montoDescuento}</span>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th scope="col">
                                                        <span style={{ fontWeight: 400, fontSize: "2rem" }} >TOTAL</span>
                                                    </th>
                                                    <th scope="col" style={{ paddingInline: "0" }}>
                                                        <span style={{ fontWeight: 400, fontSize: "2rem" }} >{presupuesto?.moneda?.simbolo}</span>
                                                    </th>
                                                    <th scope="col" style={{ paddingInline: "0", textAlign: 'right' }}>
                                                        <span style={{ fontWeight: 400, fontSize: "2rem" }} >{montoTotalDescuento}</span>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Container>
            </div>
        </>
    );
}


export default connect
    (
        state => ({
            client: state.clientes.cliente,
            presupuesto: state.presupuestos.presupuesto,
            preconfirmData: state.presupuestos.preconfirmData,
            loading: state.presupuestos.loading,
            successPreconfirm: state.presupuestos.successPreconfirm,
        }),
        dispatch => ({
            requestCliente: (id) => dispatch(requestCliente(id)),
            requestPreconfirmData: (presupuestoId) => dispatch(requestPreconfirmData(presupuestoId)),
            requestPreconfirmPresupuesto: (presupuesto) => dispatch(requestPreconfirmPresupuesto(presupuesto))
        })
    )(EnvioPago);