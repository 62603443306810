import {
	Children,
	cloneElement,
	isValidElement,
	useEffect,
	useRef,
	useState,
} from 'react';
import colores from '../../shared/constants/styles';

interface MapProps extends google.maps.MapOptions {
	center: google.maps.LatLngLiteral;
	zoom?: number;
	style: { [key: string]: string };
	onClick?: (e: google.maps.MapMouseEvent) => void;
	onIdle?: (map: google.maps.Map) => void;
	location: string;
	setLocation: (value: {}) => void;
}

const ViewMap: React.FunctionComponent<MapProps> = ({ 
	center, 
	zoom = 14, 
	style, 
	children, 
	onClick, 
	onIdle, 
	location, 
	setLocation 
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const [map, setMap] = useState<google.maps.Map | null>(null);
	const [searchInput, setSearchInput] = useState<string>('');
	const [marker, setMarker] = useState<google.maps.Marker | null>(null);

	useEffect(() => {
		if (ref.current && !map) {
			setMap(
				new window.google.maps.Map(ref.current, {
					center: new google.maps.LatLng(center.lat, center.lng),
					zoom,
					scrollwheel: false,
					draggable: true,
					mapTypeControl: false,
					streetViewControl: false,
				})
			);
		}
	});

	const getAddress = (request: google.maps.GeocoderRequest) => {
		let geocoder = new google.maps.Geocoder();
		setTimeout(function () {
			geocoder.geocode(request).then((result) => {
				const { results } = result;
				setLocation(results[0].address_components);
			})
		}, 200);
	};


	useEffect(() => {
		let clickListener: any; // Declara clickListener fuera del bloque if
	  
		const handleMapClick = (event: google.maps.MapMouseEvent) => {
			// Obtén las coordenadas del clic
			const clickedLocation = event.latLng;
		  
			// Llama a la función getAddress para obtener la dirección desde las coordenadas del clic
			getAddress({ location: clickedLocation });
		  
			// Centra el mapa en la ubicación del clic
			map?.setCenter(clickedLocation!);
		  
			// Crea un marcador en la ubicación del clic
			if (marker) {
				marker.setMap(null); // Elimina el marcador anterior si existe
			}
			
			const newClickMarker = new google.maps.Marker({
			  position: clickedLocation,
			  map,
			  title: 'Ubicación de clic',
			});
		  
			// Almacena la referencia al marcador en el estado
			setMarker(newClickMarker);
		  };
	  
		if (map && onClick) {
		  // Limpia cualquier listener previo
		  ["click"].forEach((eventName) =>
			google.maps.event.clearListeners(map, eventName)
		  );
	  
		  // Agrega el nuevo listener de clic
		  clickListener = map.addListener("click", handleMapClick);
		}
	  
		// Asegúrate de eliminar el listener cuando el componente se desmonte
		return () => {
		  if (clickListener) {
			google.maps.event.removeListener(clickListener);
		  }
		  if (marker) {
			marker.setMap(null);
		  }
		};
	  }, [map, onClick, center]);
	//   }, [map, onClick, onIdle]);

	 // Agregar una función para manejar la búsqueda
	 const handleSearch = () => {
		if (searchInput && map) {
		  const geocoder = new google.maps.Geocoder();
		  geocoder.geocode({ address: searchInput }, (results, status) => {
			if (status === 'OK' && results![0]) {
				const resultLocation = results![0].geometry.location;
				// Eliminar el marcador anterior si existe
				if (marker) {
					marker.setMap(null);
				}

			  	// Crear un nuevo marcador en la ubicación encontrada
				const newMarker = new google.maps.Marker({
				position: resultLocation,
				map,
				title: 'Ubicación encontrada',
				});

				// Actualizar la referencia al marcador
				setMarker(newMarker);

				// Centrar el mapa en la ubicación encontrada
				map.setCenter(resultLocation);
				
				if(results){
					setLocation(results[0].address_components);
				} else {
					console.error('No se pudo encontrar la ubicación:', status);
				}
			}
		  });
		}
	  };

	useEffect(() => {
		return () => {
		  // Limpiar el marcador cuando el componente se desmonta
		  if (marker) {
			marker.setMap(null);
		  }
		};
	  }, [marker]);

	  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
		  e.preventDefault(); // Prevenir el comportamiento predeterminado del Enter (enviar formulario)
		  handleSearch(); // Ejecutar la búsqueda
		}
	  };

	return (
		<>
			<div style={{ marginBottom: '10px' }}>
				<input
				style={{ width: "75%", margin: "1vh" }}
				type="text"
				placeholder="Buscar ubicación..."
				value={searchInput}
				onChange={(e) => setSearchInput(e.target.value)}
				onKeyPress={handleInputKeyPress} // Escuchar el evento de teclado
				/>
				<button className="btn" style={{ background: colores.maincolor, color: "white", width: "18%" }}  type="button" onClick={handleSearch}>Buscar</button>
			</div>
			<div ref={ref} id='map' style={style}>
				{Children.map(children, (child) => {
					if (isValidElement(child)) {
						return cloneElement(child, { map });
					}
				})}
			</div>
		</>
	);
};

export default ViewMap;
