import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from 'react-redux';
import { useEffect } from "react";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import { login, flag } from "../../actions/auth";
import * as Yup from "yup";
import TokenService from '../../services/token';
import rutas from '../../routes/constants/routes';
import ReCAPTCHA from "react-google-recaptcha";
import colores from '../../shared/constants/styles';
import TextField from '@material-ui/core/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://mui.com/">
          Your Website
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const { REACT_APP_CAPTCHA_APIKEY } = process.env;

const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: 'url(https://source.unsplash.com/random)',
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));
  

const Login = (props) => {
    const history = useHistory();

    useEffect(() => {
        if (props.success) {
            if (TokenService.hasSession()) {
                window.location.assign(rutas.DASHBOARD)
            } 
        }

        if (TokenService.hasSession()) {
            window.location.assign(rutas.DASHBOARD)
        } 

    }, [props]) 
   
    const docType = props.docType;
    const formik = useFormik({
        initialValues: {
            identificationType: "1",
            identification: "",
            password: "",
            captchaToken: ""
        },
        validationSchema: Yup.object({
            identification: Yup.string().required("Ingrese un número válido"),
            password: Yup.string().required("Ingrese su contraseña"),
            captchaToken: Yup.string().required("Captcha requerido"),
            identificationType: Yup.number().required("Ingrese una opción")
        }),
        onSubmit: (formData) => {
            props.login(formData);
        }
    })
          const classes = useStyles(); 
        return (
          <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Ingresar
                </Typography> 
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="identification"
                    label="Número de CUIT"
                    name="identification"
                    autoComplete="email"
                    autoFocus onChange={formik.handleChange} error={formik.errors.identification}
                  />
                  {formik.touched.identification && formik.errors.identification ? (
                      <div className='text-danger'>{formik.errors.identification}</div>
                  ): null}
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    autoComplete="current-password" onChange={formik.handleChange} error={formik.errors.password}
                  />
                    {formik.touched.password && formik.errors.password ? (
                                <div className='text-danger'>{formik.errors.password}</div>
                                ): null}
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Recordarme"
                  />
                        <ReCAPTCHA
                            sitekey={REACT_APP_CAPTCHA_APIKEY}
                            error={formik.errors.captchaToken}
                            style={{margin: "0 auto",
                                display: "table"}}
                            name="captchaToken" 
                            id="captchaToken"
                            onChange={(value) => {
                                formik.setFieldValue("captchaToken", value);
                                formik.setSubmitting(false);
                                }
                            }
                        />
                    {
                        props.error ?
                        <div class="alert alert-danger"  style={{maxWidth:"100%", display: 'flex', justifyContent: 'center', alignItems: 'center'}} role="alert">
                            { props.error.response.data }
                        </div>
                        : ""
                    }


                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    //className={classes.submit}
                    className={`btn ${props.loading ? "disabled" : ""}`}
                    style={{backgroundColor: colores.maincolor}}
                  >
                    Iniciar Sesión
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link to={rutas.RESET_PASWORD} style={{borderColor:"black"}}>Recuperar contraseña</Link>
                    </Grid>
                  </Grid>
                  <Box mt={5}>
                    <Copyright />
                  </Box>
                </form>
              </div>
            </Grid>
          </Grid>
        );

}

        export default connect(
            state => ({
              session: state.auth.session,
              success: state.auth.success,
              loading: state.auth.loading,
              data: state.auth.data,
              docType: state.params.docType,
              error: state.auth.error
            }),
            dispatch => ({
              login: (loginData) => dispatch(login(loginData)),
              flag: () => dispatch(flag())
            })
          )(Login);