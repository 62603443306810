import { Types} from '../actions/producto';

const defaultProducto={
    id: '',
    descripcion: '',
    Activo: true,
    identificationType: '',
    grupo: '',
}
const defaultProductos=[
    defaultProducto
]
const defaultState={
    producto: defaultProducto,
    productos: defaultProductos,
    loading: false,
    saving: false,
    error: false,
    success: false,
    file: "",
    enviado: false
}
export default function productoReducer(state = defaultState, action) {
    switch (action.type) {
        case Types.PRODUCTO_LOADING:
            return {...state, loading: true};
        case Types.PRODUCTOS_FETCH_SUCCEEDED:
            return {...state, productos: action.productos, loading: false};
        case Types.PRODUCTOS_FETCH_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PRODUCTOS_FETCH_GRUPO_SUCCEEDED:
            return {...state, enviado:false, productos: action.productos, loading: false};
        case Types.PRODUCTOS_FETCH_GRUPO_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PRODUCTOS_FIND_SUCCEEDED:
            return {...state, enviado:false,producto: action.producto, loading: false};
        case Types.PRODUCTOS_FIND_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PRODUCTOS_SEARCH_SUCCEEDED:
            return {...state, enviado:false, productos: action.productos, loading: false};
        case Types.PRODUCTOS_SEARCH_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PRODUCTOS_CLOSE_DOWNLOAD:
            return {...state, successDownload: false};
        case Types.PRODUCTOS_DOWNLOAD_REQUESTED:
            return {...state, successDownload: false, loading: false};
        case Types.PRODUCTOS_DOWNLOAD_SUCCEEDED:
            return {...state, file: action.file, enviado:false, loading: false, successDownload: true};
        case Types.PRODUCTOS_DOWNLOAD_ERROR:
            return {...state, error: action.err, loading: false, successDownload: false};
        case Types.PRODUCTOS_SEARCH_ALL_SUCCEEDED:
            return {...state, productos: action.productos, enviado:false, loading: false, success: true};
        case Types.PRODUCTOS_SEARCH_ALL_ERROR:
            return {...state, error: action.err, loading: false, success: false};
        case Types.PRODUCTOS_CONSULTAR_DISPONIBILIDAD_SUCCEEDED:
            return {...state, enviado: action.enviado, loading: false, success: false};
        case Types.PRODUCTOS_CONSULTAR_DISPONIBILIDAD_ERROR:
            return {...state, error: action.err, loading: false, success: false};
        default:
            return state;
    }
}