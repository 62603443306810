import React, { useEffect, useState } from "react";
import Dolar from "../../routes/dolar";
import colores from "../../shared/constants/styles";
import { Grid, debounce } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import {
  requestPresupuestoConfirm,
  requestConfirmacionPresupuesto,
  requestGetPresupuestoDetalles,
} from "../../actions/presupuesto";
import { useParams, useHistory } from "react-router-dom";
import rutas from "../../routes/constants/routes";
import logo from "../../assets/images/Barbieri-MarcaGrafica.png";
import { Link } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getTerminosCondiciones } from "../../actions/configuracion";

const Confirmar = (props) => {
  const history = useHistory();
  const expirationTime = JSON.parse(
    window.localStorage.getItem("expirationtime")
  );
  const realExpirationTime = JSON.parse(
    window.localStorage.getItem("LocalSession")
  ).shoopExpirationTime;
  const time = expirationTime ? expirationTime : 300;
  const realTime = realExpirationTime ? realExpirationTime : 300;
  const [tiempoRestante, setTiempoRestante] = useState(time);
  const dispatch = useDispatch();
  const [detallesProductos, setDetallesProductos] = useState();
  const [confirmDataProductos, setConfirmDataProductos] = useState("$");
  const [textArea, setTextArea] = useState("");
  const [esPedidoFirme, setPedidoFirme] = useState(null);
  const [alertaPedido, setAlertaPedido] = useState(false);
  const [error, setError] = useState("");
  const [terminosCondiciones, setTerminosCondiciones] = useState("");
  const [openDialogTerminos, setOpenDialogTerminos] = useState(false);

  var { idPresupuesto, idUser } = useParams();
  // Función para formatear el tiempo en minutos y segundos
  const formatTiempoRestante = (tiempo) => {
    const minutos = Math.floor(tiempo / 60);
    const segundos = tiempo % 60;
    return `${minutos < 10 ? "0" : ""}${minutos}:${
      segundos < 10 ? "0" : ""
    }${segundos}`;
  };

  const handleChangeTextArea = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length <= 150) {
      setTextArea(inputValue);
      setError("");
    } else {
      setError(
        "Se ha excedido el límite de caracteres. 150 carácteres es el máximo."
      );
    }
  };

  const handleSelectPedidoFirma = (event) => {
    setPedidoFirme(event.target.value == 1);
    setAlertaPedido(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getTerminosCondiciones());
    dispatch(requestPresupuestoConfirm(idPresupuesto, idUser));
    return () => {
      localStorage.setItem("expirationtime", realTime);
    };
  }, []);

  useEffect(() => {
    const tiempoExpiracion = time;
    const temporizador = setInterval(() => {
      setTiempoRestante((prevTiempo) => {
        const nuevoTiempo = prevTiempo - 1;
        // Guarda el nuevo tiempo restante en el almacenamiento local
        localStorage.setItem("expirationtime", nuevoTiempo);
        return nuevoTiempo;
      });
    }, 1000);

    const tiempoExpiracionMillis = tiempoExpiracion * 1000;

    const redirigir = () => {
      localStorage.setItem("expirationtime", realTime);
      history.push(`${rutas.CARRITO}`);
    };

    const expiracionTimeout = setTimeout(() => {
      redirigir();
    }, tiempoExpiracionMillis);

    return () => {
      clearInterval(temporizador);
      clearTimeout(expiracionTimeout);
    };
  }, [history]);

  const titlesBig = {
    fontWeight: "bold",
    fontSize: "1.50rem",
  };

  const dateTime = (date) => {
    let parseDate = new Date(Date.parse(date));
    return parseDate.toLocaleString().slice(0, -3);
  };

  const noTitlesBig = {
    fontWeight: "normal",
    fontSize: "1.25rem",
  };

  const titles = {
    fontWeight: "bold",
    fontSize: "1.1rem",
    marginBottom: "0.5rem",
    padding: "0",
  };

  const noTitles = {
    fontWeight: "normal",
    fontSize: "1.1rem",
    marginLeft: "0.313rem",
    marginTop: "0.313rem",
    marginBottom: "0.313rem",
    padding: "0",
  };

  const titlesLine = {
    fontWeight: "bold",
    fontSize: "1.1rem",
  };

  const noTitlesLine = {
    fontWeight: "normal",
    fontSize: "1rem",
  };

  const confirmarPresupuesto = () => {
    if (esPedidoFirme != null) {
      setOpenDialogTerminos(true);
    } else {
      setAlertaPedido(true);
    }
  };

  const volverMiCarrito = () => {
    history.push(`${rutas.CARRITO}`);
  };

  const handleConfirmarTerminosCondiciones = () => {
    localStorage.setItem("expirationtime", realTime);
    setOpenDialogTerminos(false);
    dispatch(
      requestConfirmacionPresupuesto(idPresupuesto, textArea, esPedidoFirme)
    );
  };

  useEffect(() => {
    if (props.successPre === true) {
      window.location.assign(rutas.MIS_PRESUPUESTOS);
    }
  }, [history, props]);

  const calcularPrecioUnitario = (detalle, condicionPago) => {
    let precioUnitario = 0;
    if (detalle?.dimension1 > 1) {
      precioUnitario =
        detalle.montoSubtotalDto / detalle?.dimension1 / detalle?.cantidadTiras;
    } else {
      precioUnitario = detalle.montoSubtotalDto / detalle?.cantidadTiras;
    }
    return precioUnitario;
  };
  const calcularTotalKilos = (items) => {
    let totalKilos = 0;

    if (items !== undefined) {
      for (let i = 0; i < items.length; i++) {
        totalKilos = items[i].pesoSubtotal + totalKilos;
      }
    }
    return totalKilos;
  };
  const calcularSubtotal = (items) => {
    let totalSubtotal = 0;

    if (items !== undefined) {
      for (let i = 0; i < items.length; i++) {
        const ml = items[i].dimension1 > 1 ? items[i].dimension1 : 1;
        totalSubtotal =
          items[i].precio * items[i].cantidadTiras * ml + totalSubtotal;
      }
    }
    return totalSubtotal;
  };
  const calcularDescuentos = (items) => {
    let totalDescuentos = 0;
    let subtotal = 0;
    let total = 0;

    if (items !== undefined) {
      for (let i = 0; i < items.length; i++) {
        const ml = items[i].dimension1 > 1 ? items[i].dimension1 : 1;
        subtotal = items[i].precio * items[i].cantidadTiras * ml + subtotal;
        total = items[i].montoSubtotalDto + total;
      }
    }

    totalDescuentos = subtotal - total;
    return totalDescuentos;
  };
  const calcularTotal = (items) => {
    let total = 0;
    if (items !== undefined) {
      for (let i = 0; i < items.length; i++) {
        total = items[i].montoSubtotalDto + total;
      }
    }
    return total;
  };
  const redondear = (monto) => {
    return Math.round((monto + Number.EPSILON) * 100) / 100;
  };

  useEffect(() => {
    setConfirmDataProductos(
      props.presupuestoConfirm?.detalles?.$values[0]?.moneda?.simbolo
    );
    if (props.presupuesto?.presupuestoDetalles?.$values) {
      setDetallesProductos(props.presupuesto?.presupuestoDetalles?.$values);
    }
  }, [props.presupuestoConfirm]);

  useEffect(() => {
    if (props.successGetTerminos && !props.loading) {
      setTerminosCondiciones(props.terminos);
    }
  }, [props.successGetTerminos, props.loading]);

  useEffect(() => {
    setConfirmDataProductos(
      props.presupuestoConfirm?.detalles?.$values[0]?.moneda?.simbolo
    );
    if (props.presupuesto?.presupuestoDetalles?.$values) {
      setDetallesProductos(props.presupuesto?.presupuestoDetalles?.$values);
    }
  }, [props.presupuesto]);

  const handleCloseTerminosCondiciones = () => {
    setOpenDialogTerminos(false);
  };

  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <div>
        <Dolar></Dolar>

        <div
          className="d-flex justify-content-center"
          style={{ backgroundColor: colores.grisClaro }}
        >
          <hr style={{ width: "90%", color: "black" }}></hr>
        </div>
        <div
          className="d-flex justify-content-between flex-wrap align-items-center"
          style={{
            marginBottom: "0.600rem",
            paddingRight: "3rem",
            backgroundColor: colores.grisClaro,
          }}
        >
          <h1
            className="fw-bolder"
            style={{ marginLeft: "5%", fontWeight: "bold" }}
          >
            CONFIRMAR
          </h1>
          <div style={{ float: "right", marginRight: "25vw" }}>
            <h3>
              Tiempo restante para realizar la compra:{" "}
              {formatTiempoRestante(tiempoRestante)}
            </h3>
          </div>
        </div>
        <div
          className="container"
          style={{
            backgroundColor: colores.grisClaro,
            borderRadius: "15px 15px 0 0",
            padding: "3.125rem",
            marginTop: "5rem",
            marginBottom: "5rem",
            marginLeft: "5rem",
            marginRight: "5rem",
          }}
        >
          <div className="container" style={{ margin: 0 }}>
            <div className="fluid" style={{ marginBottom: "5rem" }}>
              <img src={logo} alt="logo" height="60rem"></img>
            </div>
            <div style={{}}>
              <div style={titlesBig}>
                SOLICITUD DE PRESUPUESTO:{" "}
                <span style={noTitlesBig}>
                  {props.presupuestoConfirm?.pedidoId}
                </span>{" "}
              </div>
              <div style={titlesBig}>
                FECHA:{" "}
                <span style={noTitlesBig}>
                  {dateTime(props.presupuestoConfirm?.fecha)}
                </span>
              </div>
            </div>
            <div>
              <hr />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="col" style={titles}>
                  CLIENTES:{" "}
                  <span style={noTitles}>
                    {props.presupuestoConfirm?.cliente}
                  </span>
                </div>
                <div className="col" style={titles}>
                  CUIT:{" "}
                  <span style={noTitles}>
                    {props.presupuestoConfirm?.documentoCliente}
                  </span>
                </div>
                <div className="col" style={titles}>
                  DOMICILIO:{" "}
                  <span style={noTitles}>
                    {props.presupuestoConfirm?.direccionCliente}
                  </span>
                </div>
              </div>
              <div>
                <div className="col" style={titles}>
                  TELEFONO:{" "}
                  <span style={noTitles}>
                    {props.presupuestoConfirm?.telefonoCliente}
                  </span>
                </div>
                <div className="col" style={titles}>
                  LISTA DE PRECIO:{" "}
                  <span style={noTitles}>
                    {props.presupuestoConfirm?.listaPrecio}
                  </span>
                </div>
                <div className="col" style={titles}>
                  VENDEDOR:{" "}
                  <span style={noTitles}>
                    {props.presupuestoConfirm?.vendedor}
                  </span>
                </div>
                {/* <div className='col' style={titles}>TIPO DE CAMBIO: <span style={noTitles}>{props.presupuestoConfirm?.tipoCambio}</span></div> */}
              </div>
              <div>
                <div className="col" style={titles}>
                  MONEDA:{" "}
                  <span style={noTitles}>
                    {props.presupuestoConfirm?.moneda}
                  </span>
                </div>
                <div className="col" style={titles}>
                  CONDICION DE PAGO:{" "}
                  <span style={noTitles}>
                    {props.presupuestoConfirm?.condicionPago}
                  </span>
                </div>
                <div className="col" style={titles}>
                  TIPO DE ENVÍO:{" "}
                  <span style={noTitles}>
                    {props.presupuestoConfirm?.clasificacionEnvio}{" "}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <hr />
            </div>
            <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
              <h4>DETALLE DEL PEDIDO (No contiene impuestos)</h4>
            </div>
            <Grid style={{ marginBottom: "1.5rem" }}>
              <Grid
                className="d-flex justify-content-between align-items-center"
                style={{}}
              >
                <Grid item xs={5}>
                  <p style={titlesLine}>CÓDIGO PRODUCTO</p>
                </Grid>
                <Grid item xs>
                  <p style={titlesLine}>CANT</p>
                </Grid>
                <Grid item xs>
                  <p style={titlesLine}>KILOS</p>
                </Grid>
                <Grid item xs>
                  <p style={titlesLine}>P. LISTA</p>
                </Grid>
                <Grid item xs>
                  <p style={titlesLine}>% BON</p>
                </Grid>
                <Grid item xs>
                  <p style={titlesLine}>P. UNIT</p>
                </Grid>
                <Grid item xs>
                  <p style={titlesLine}>IMPORTE</p>
                </Grid>
              </Grid>
              {detallesProductos &&
                detallesProductos?.map((detalle) => (
                  <Grid
                    className="d-flex justify-content-between align-items-center"
                    style={{}}
                    key={detalle.$id}
                  >
                    <Grid item xs={5}>
                      {detalle?.dimension1 > 1 ? (
                        <p style={noTitlesLine}>
                          {detalle.producto?.descripcion}-{" "}
                          {detalle?.dimension1.toFixed(2)} ml{" "}
                        </p>
                      ) : (
                        <p style={noTitlesLine}>
                          {detalle.producto?.descripcion}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs>
                      <p style={noTitlesLine}>{detalle.cantidadTiras}</p>
                    </Grid>
                    <Grid item xs>
                      <p style={noTitlesLine}>
                        {detalle.pesoSubtotal?.toFixed(2)}
                      </p>
                    </Grid>
                    <Grid item xs>
                      <p style={noTitlesLine}>
                        {confirmDataProductos} {detalle.precio.toFixed(2)}
                      </p>
                    </Grid>
                    <Grid item xs>
                      <p style={noTitlesLine}>
                        {detalle?.descuento1 > 0
                          ? detalle?.descuento1.toFixed(2)
                          : ""}
                        {props.presupuestoConfirm?.descuentoCondicionPago > 0 &&
                          (detalle?.descuento1 > 0
                            ? " + " +
                              props.presupuestoConfirm?.descuentoCondicionPago
                            : props.presupuestoConfirm?.descuentoCondicionPago)}
                        {props.presupuestoConfirm?.descuentoCliente > 0
                          ? detalle?.descuento1 > 0 ||
                            props.presupuestoConfirm?.descuentoCondicionPago > 0
                            ? " + " + props.presupuestoConfirm?.descuentoCliente
                            : props.presupuestoConfirm?.descuentoCliente
                          : ""}
                        {!(props.presupuestoConfirm?.descuentoCliente > 0) &&
                        !(
                          props.presupuestoConfirm?.descuentoCondicionPago > 0
                        ) &&
                        !(detalle?.descuento1 > 0)
                          ? "0"
                          : ""}
                      </p>
                      {/*                             <p style={noTitlesLine}>{detalle?.descuento1 > 0 ? detalle?.descuento1 +' + ' : <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}{props.presupuestoConfirm?.descuentoCondicionPago > 0 ? props.presupuestoConfirm?.descuentoCondicionPago +' + ' : ''}{props.presupuestoConfirm?.descuentoCliente > 0 ? props.presupuestoConfirm?.descuentoCliente : ''}</p>
                       */}{" "}
                    </Grid>
                    <Grid item xs>
                      <p style={noTitlesLine}>
                        {confirmDataProductos}{" "}
                        {calcularPrecioUnitario(
                          detalle,
                          props.presupuestoConfirm?.descuentoCondicionPago
                        ).toFixed(2)}
                      </p>
                      {/* {detalle?.dimension1 > 1 ? (
                          <p style={noTitlesLine}>{confirmDataProductos} {(detalle.montoSubtotalDto/ detalle?.dimension1/ detalle?.cantidadTiras).toFixed(2) }</p>
                      ) : <p style={noTitlesLine}>{confirmDataProductos} {(detalle.montoSubtotalDto/ detalle?.cantidadTiras).toFixed(2)}</p>
                      }
 */}
                    </Grid>
                    <Grid item xs>
                      <p style={noTitlesLine}>
                        {confirmDataProductos}{" "}
                        {detalle.montoSubtotalDto.toFixed(2)}
                      </p>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            <div>
              <hr />
            </div>
            <div>
              <div style={{ marginBottom: "1.5rem" }}>
                <Grid>
                  <Grid
                    className="d-flex justify-content-between align-items-center"
                    style={{}}
                  >
                    <Grid item xs>
                      <p style={titles}>TOTAL KILOS:</p>
                    </Grid>
                    <Grid item xs>
                      <p style={titles}>SUBTOTAL:</p>
                    </Grid>
                    <Grid item xs>
                      <p style={titles}>DESCUENTOS:</p>
                    </Grid>
                    <Grid item xs>
                      <p style={titles}>TOTAL:</p>
                    </Grid>
                  </Grid>
                  <Grid
                    className="d-flex justify-content-between align-items-center"
                    style={{}}
                  >
                    <Grid item xs>
                      <p style={noTitles}>
                        {detallesProductos && detallesProductos
                          ? calcularTotalKilos(detallesProductos).toFixed(2)
                          : 0}
                      </p>
                    </Grid>
                    <Grid item xs>
                      <p style={noTitles}>
                        {confirmDataProductos}{" "}
                        {detallesProductos && detallesProductos
                          ? calcularSubtotal(detallesProductos).toFixed(2)
                          : 0}
                      </p>
                    </Grid>
                    <Grid item xs>
                      <p style={noTitles}>
                        {confirmDataProductos}{" "}
                        {detallesProductos && detallesProductos
                          ? calcularDescuentos(detallesProductos).toFixed(2)
                          : 0}
                      </p>
                    </Grid>
                    <Grid item xs>
                      <p style={titles}>
                        {confirmDataProductos}{" "}
                        {detallesProductos && detallesProductos
                          ? calcularTotal(detallesProductos).toFixed(2)
                          : 0}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <div className="col" style={titles}>
                  OBS/COMENTARIOS:{" "}
                  <span style={noTitles}>
                    {props.presupuestoConfirm?.comentario}
                  </span>
                </div>
                <div className="col" style={titles}>
                  TIENDA DE ENTREGA:{" "}
                  <span style={noTitles}>
                    {props.presupuestoConfirm?.tiendaEntrega}
                  </span>
                </div>
                <div className="col" style={titles}>
                  DOMICILIO DE ENTREGA:{" "}
                  <span style={noTitles}>
                    {props.presupuestoConfirm?.direccionEntrega}
                  </span>
                </div>
                <div className="col" style={titles}>
                  TELÉFONO ENTREGA:{" "}
                  <span style={noTitles}>
                    {props.presupuestoConfirm?.telefonoEntrega}
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{}}
              className="row row-cols-1 row-cols-sm-1 row-cols-md-1 "
            >
              <div
                className="col text-center"
                style={{
                  color: "white",
                  backgroundColor: "black",
                  fontWeight: "bold",
                  padding: "1.25rem",
                }}
              >
                <h3 className="text-center">ATENCIÓN</h3>
                <h5 className="text-center">
                  LA PRESENTE SOLICITUD DE COMPRA DEBERÁ SER APROBADA POR SU
                  COMERCIAL ASIGNADO
                </h5>
                <h5 className="text-center">
                  (VER ESTADO EN{" "}
                  <Link
                    href="#"
                    to={rutas.MIS_PRESUPUESTOS}
                    style={{ textDecoration: "none", color: colores.grisClaro }}
                  >
                    "MIS OPERACIONES"
                  </Link>
                  ) PARA FORMALIZAR LA COMPRA
                </h5>
                <br></br>
                <h5 className="text-center">
                  EL SIGUIENTE PEDIDO DE PRESUPUESTO ESTA SUJETO A VALIDACION DE
                  STOCK
                </h5>
                
              </div>
            </div>

            <div
              style={{ marginTop: "5vh" }}
              className="row row-cols-1 row-cols-sm-1 row-cols-md-1 "
            >
              <div className="col" style={titles}>
                OBSERVACIONES ADICIONALES:{" "}
              </div>
              <textarea value={textArea} onChange={handleChangeTextArea} />
              <div className="error-message">{error}</div>
            </div>

            <div
              style={{ marginTop: "5vh" }}
              className="row row-cols-1 row-cols-sm-1 row-cols-md-1 "
            >
              <div className="col" style={titles}>
                ¿ES PEDIDO FIRME?*
              </div>
            </div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={handleSelectPedidoFirma}
              >
                <FormControlLabel value="1" control={<Radio />} label="Si" />
                <FormControlLabel value="0" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            {alertaPedido && (
              <Alert severity="error">
                Debe confirmar si el pedido es firme o un presupuesto que requiere asistencia comercial.
              </Alert>
            )}

            <div
              style={{}}
              className="row row-cols-1 row-cols-sm-1 row-cols-md-1 "
            >
              <div
                className="col text-center"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  padding: "1.875rem",
                }}
              >
                <button
                  className={`btn ${props.loading ? "disabled" : ""}`}
                  disabled={props.error}
                  onClick={confirmarPresupuesto}
                  style={{
                    width: "12.5rem",
                    backgroundColor: colores.maincolor,
                    color: "white",
                  }}
                >
                  CONFIRMAR SOLICITUD
                </button>
                { props.loading && 
                (<div style={{margin: "1vw"}}> <CircularProgress /> </div>)
                }
              </div>
              {props.error && (
                <div
                  className="alert alert-danger text-center"
                  style={{ width: "17.5rem" }}
                  role="alert"
                >
                  {props?.error}
                </div>
              )}
              {props.error &&
              (<div className="col text-center">
                <button
                  className={`btn`}
                  onClick={volverMiCarrito}
                  style={{
                    width: "12.5rem",
                    backgroundColor: colores.maincolor,
                    color: "white",
                  }}
                  > VOLVER MI CARRITO
                </button>
              </div>)}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openDialogTerminos}
        onClose={handleCloseTerminosCondiciones}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        PaperProps={{
           style: {
             width: "800px", 
             maxWidth: "800px", 
           },
        }}      >
        <DialogTitle id="alert-dialog-title">
          {/* {"Términos y condiciones"} */}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseTerminosCondiciones}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="d-flex justify-content-between align-items-center">
              <div className="content" dangerouslySetInnerHTML={{ __html: terminosCondiciones }}></div>

            </div>
          </DialogContentText>
          <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            He leído y acepto los términos y condiciones.
        </DialogContent>
        <div style={{ textAlign: "center", margin: "2vw" }}>
            
          <button
            className={`btn ${props.loading ? "disabled" : ""}`}
            disabled={!checked}
            onClick={handleConfirmarTerminosCondiciones}
            style={{
              width: "12.5rem",
              backgroundColor: colores.maincolor,
              color: "white",
            }}
          >
            REALIZAR PEDIDO
          </button>
        </div>
      </Dialog>
    </>
  );
};

export default connect(
  (state) => ({
    presupuesto: state.presupuestos.presupuesto,
    presupuestoConfirm: state.presupuestos.presupuestoConfirm,
    loading: state.presupuestos.loading,
    error: state.presupuestos.errorMsj,
    successPre: state.presupuestos.successPre,
    successGetTerminos: state.configuracion.successGetTerminos,
    terminos: state.configuracion.terminos,
    loadingTerminos: state.configuracion.loading,
  }),
  (dispatch) => ({
    // controller/FetchPreconfirm
    requestPresupuestoConfirm: (idPresupuesto, idUser) =>
      dispatch(requestPresupuestoConfirm(idPresupuesto, idUser)),
    //controller/ConfirmBudget
    requestConfirmacionPresupuesto: (idPresupuesto, observaciones) =>
      dispatch(requestConfirmacionPresupuesto(idPresupuesto, observaciones)),
    getTerminosCondiciones: () => dispatch(getTerminosCondiciones()),
  })
)(Confirmar);
