import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  requestProductosPorGrupo,
  requestProductosSearch,
} from "../../actions/producto";
import { requestGrupos } from "../../actions/params";
import CardProduct from "./cardProduct";
import ImagenCabecera from "../../routes/imagenCabecera";
import Dolar from "../../routes/dolar";
import colores from "../../shared/constants/styles";
import { isAllowed } from "../../shared/utils/permissions";
import MuiCustomInput from "./MuiCustomInput";
import CardMessage from "./CardMessage";
import LoadingCart from "../ShoppingCart/LoadingCart";

const Productos = (props) => {
  const [quanty, setQuanty] = useState(0);
  const match = useHistory();
  const { id, prod } = useParams();
  const dispatch = useDispatch();
  const { grupos, productos } = props;
  const [categoria, setCategoria] = React.useState(null);
  const [descripcion, setDescripcion] = React.useState("");
  const [flagForm, setFlagForm] = React.useState(false);
  const [flagText, setFlagText] = React.useState(false);
  const [busqueda, setBusqueda] = React.useState("");
  const [productosFiltrados, setProductosFiltrados] = useState([]);
  const [grupo, setGrupo] = useState();

  const gruposDropdown = [
    ...new Map(productos?.map((item) => [item["gruposProdId"], item])).values(),
  ];
  const localStorage = JSON.parse(window.localStorage.getItem("LocalSession"));
  const selectedClient = JSON.parse(
    window.localStorage.getItem("selectedClient")
  );

  useEffect(() => {
    window.localStorage.setItem("grupoId", JSON.stringify(id));
    setCategoria(null);
    if (isAllowed(18)) {
      setDescripcion("");
      setCategoria(id);
      if (selectedClient) {
        props.requestProductosPorGrupo(id, selectedClient[0].id);
      }
      if (localStorage.tipoRol === 3) {
        props.requestProductosPorGrupo(id, localStorage.clientId);
      }
    }
    dispatch(requestGrupos());
  }, []);

  useEffect(() => {
    let g = grupos?.find(
      (g) => g.grupoId.toLocaleLowerCase() === id.toLocaleLowerCase()
    );
    setGrupo(g);
  }, [grupos]);

  const handleChange = (value) => {
    setFlagForm(true);
    setCategoria(id);
    descripcion
      ? props.requestProductosSearch(id, descripcion)
      : props.requestProductosPorGrupo(id, localStorage.clientId);
    setBusqueda(value);
  };

  const handleSearch = (value) => {
    value
      ? props.requestProductosSearch(categoria, value)
      : props.requestProductosPorGrupo(categoria, localStorage.clientId);
    value?.length === 0 ? setFlagText(false) : setFlagText(true);
    setDescripcion(value);
  };

  return (
    <div style={{ backgroundColor: colores.grisClaro }}>
      <div style={{ 
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        {props.loading ? (
          <div style={{ height: "120px" }}></div>
        ) : (
          <img src={grupo?.imagen} width={900} alt="grupo" />
        )}
      </div>
      <Dolar></Dolar>
      <div>
        <div
          className="container-fluid d-flex flex-row mb-3 flex-wrap"
          style={{ marginLeft: "3.7rem", padding: "1rem" }}
        >
          {/* <div style={{width: "100%", height: "100%", overflow:"hidden", clear:"both"}}>
                        <h1 className="float-start fw-bolder">PRODUCTOS</h1>
                    </div> */}
          <div className="fluid d-flex flex-start flex-wrap">
            <div
              style={{
                alignSelf: "center",
                display: "block",
                marginInlineEnd: "4rem",
              }}
            >
              <p
                style={{
                  fontSize: "1.2rem",
                  alignSelf: "center",
                  margin: "0rem",
                  display: "block",
                }}
              >
                BÚSQUEDA POR SUBPRODUCTOS
              </p>
            </div>
            <MuiCustomInput
              component={"FormControl"}
              handleChange={handleChange}
              gruposDropdown={gruposDropdown}
              productos={productos}
              setFlagForm={setFlagForm}
              flagForm={flagForm}
            />
            <MuiCustomInput
              component={"TextField"}
              handleSearch={handleSearch}
              value={descripcion}
              flagText={flagText}
            />
          </div>
        </div>
      </div>
      <div>
        <div
          className="container-fluid d--flex flex-row mb-3 flex-wrap"
          style={{ backgroundColor: "white", padding: "1rem" }}
        >
          <div
            className="container-fluid"
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              clear: "both",
            }}
          >
            <div
              className="container d-flex flex-row flex-wrap"
              style={{ marginBlock: "2rem" }}
            >
              {props.loading ? (
                <LoadingCart></LoadingCart>
              ) : productos.length >= 1 ? (
                busqueda === "" ? (
                  productos
                    ?.filter((producto) => {
                      const search = producto.descripcion.search(
                        descripcion.toUpperCase()
                      );
                      return search !== -1 || descripcion === "";
                    })
                    .map(
                      (producto) =>
                        producto.id !== "" &&
                        (!prod || prod === producto.id) && (
                          <CardProduct key={producto.id} data={producto} />
                        )
                    )
                ) : (
                  productos
                    ?.filter((producto) => {
                      const search = producto.descripcion.search(
                        descripcion.toUpperCase()
                      );
                      return search !== -1 || descripcion === "";
                    })
                    .map(
                      (producto) =>
                        producto.id !== "" &&
                        (!prod || prod === producto.id) &&
                        producto.gruposProdId === busqueda && (
                          <CardProduct key={producto.id} data={producto} />
                        )
                    )
                )
              ) : localStorage.tipoRol === 3 ? (
                <CardMessage cliente={true}></CardMessage>
              ) : (
                <CardMessage cliente={false}></CardMessage>
              )}
              {/*                             { productos.length >= 2
                                ? (
                                    busqueda === '' ?
                                    productos?.filter(producto => {
                                        const search = producto.descripcion.search(descripcion.toUpperCase());
                                        return search !== -1 || descripcion === '' ;
                                    }).map(producto => (
                                        (producto.id !== '' && (!prod || prod === producto.id)) && <CardProduct key={producto.id} data={producto} />
                                    ))
    
                                  :
                                    productos?.filter(producto => {
                                        const search = producto.descripcion.search(descripcion.toUpperCase());
                                        return search !== -1 || descripcion === '' ;
                                    }).map(producto => (
                                        (producto.id !== '' && (!prod || prod === producto.id) && producto.gruposProdId === busqueda) && <CardProduct key={producto.id} data={producto} />
                                    ))
                                )
                                : ( localStorage.tipoRol === 3
                                    ? <CardMessage cliente={true}></CardMessage>
                                    : <CardMessage cliente={false}></CardMessage>)
                            } */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    productos: state.producto.productos,
    grupos: state.params.grupos,
    loading: state.producto.loading,
  }),
  (dispatch) => ({
    requestProductosSearch: (id, search) =>
      dispatch(requestProductosSearch(id, search)),
    requestProductosPorGrupo: (id, clientId) =>
      dispatch(requestProductosPorGrupo(id, clientId)),
    requestGrupos: () => dispatch(requestGrupos()),
  })
)(Productos);
