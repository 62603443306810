import colores from "../shared/constants/styles";

const FooterPage = () => {
  const style = { background: colores.grisOscuro, color: "white" };

  return (
    <footer style={style} className="p-5">
 
      <span>
        <hr></hr>
      </span>
      <span style={{ fontSize: 14 }}>
        Todos los derechos reservados Bionodum ©2022
      </span>
    </footer>
  );
};

export default FooterPage;
