import React from 'react';
import { useEffect } from "react";
import { useFormik } from "formik";
import { connect } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import { login, logout } from '../../actions/users';
import * as Yup from "yup";
import TokenService from '../../services/token';
import rutas from '../../routes/constants/routes'
import ReCAPTCHA from "react-google-recaptcha";
import {
  Avatar,
  Button,
  CssBaseline,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  TextField,
  Grid,
  Typography,
  Container
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import colores from '../../shared/constants/styles';

const { REACT_APP_TEST_SITE_KEY } = process.env;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colores.maincolor,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn = (props) => {
  const history = useHistory();
  useEffect(() => {
    props.logout();
    if (props.session !== null && props.session !== undefined) {
      TokenService.setToken(props.session);
      history.push(rutas.DASHBOARD)
    } else {
      TokenService.clear();
    }
  }, [props.session])
  
  const docType = props.docType;
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      docType: "",
      number: "",
      password: "",
      recaptcha: ""
    },
    validationSchema: Yup.object({
      docType: Yup.string().required(),
      number: Yup.string().required(),
      password: Yup.string().required(),
      recaptcha: Yup.string().required()
    }),
    onSubmit: (formData) => {
      props.login(formData);
    }
  })

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Complete sus datos
        </Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="docTypeLabel">Tipo</InputLabel>
                <Select
                  defaultValue=""
                  labelId="docTypeLabel"
                  id="demo-simple-select"
                  label="Tipo de documento"
                  name="docType"
                  required
                  onChange={formik.handleChange}
                  error={formik.errors.docType && true}
                >
                  {docType?.map((element, index) => {
                      return <MenuItem key={index} value={element.id}>{element.name}</MenuItem>
                  })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <TextField  
                  required
                  fullWidth
                  id="number"
                  label="Numero de documento"
                  name="number"
                  autoComplete="number"
                  onChange={formik.handleChange}
                  error={formik.errors.number && true}
                />
            </Grid>
          </Grid>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="password"
            onChange={formik.handleChange}
            error={formik.errors.password && true}
          />
          <ReCAPTCHA
            sitekey={REACT_APP_TEST_SITE_KEY}
            onChange={(value) => {
              formik.setFieldValue("recaptcha", value);
              formik.setSubmitting(false);
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Iniciar sesion
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to={rutas.RESET_PASWORD}>
                Recuperar contraseña
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

export default connect(
  state => ({
    session: state.users.session,
    success: state.users.success,
    docType: state.params.docType
  }),
  dispatch => ({
    login: (loginData) => dispatch(login(loginData)),
    logout: () => dispatch(logout())
  })
)(SignIn);