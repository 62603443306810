import { useState } from "react";
import { Button } from '@material-ui/core';
import colores from '../../shared/constants/styles';
import { connect } from 'react-redux';
import TimelineComponent from './timeline';

const Reclamo = (props) => {
    const [showDetalle, setShowDetalle] = useState(false);
    const handleClick = () => {
        setShowDetalle(!showDetalle)
    }
    
    return (
        <>
            <div className="bordesRedondos col-12 col-sm-7 col-md-5 col-lg-4" style={{ margin: "1.875rem 0" }}>
                <div className="bordesRedondosTop white-color p-4 text-uppercase" style={{ backgroundColor: colores.azulOscuro }}>
                    <div>
                        <div>
                            <div className="atributoTitle"><h4 style={{ fontWeight: 'lighter' }}>RECLAMO: <strong>{`${props.data?.nroReclamo}`}</strong></h4></div>
                        </div>
                    </div>
                </div>
                <div style={{ backgroundColor: colores.grisClaro }} className="bordesRedondosBottom p-4">
                    <div className="d-flex flex-row bd-highlight">
                        <div className="mb-3" style={{ fontWeight: "bold", marginRight: "1.25rem" }}>
                            <h3 style={{ fontWeight: "bold" }} className="atributoDato">FECHA: </h3>
                            <h3 style={{ fontWeight: "bold" }} className="atributoDato">TITULO:</h3>
                        </div>
                        <div className="mb-3">
                            <h3 className="atributoDato">{props.data?.fechaCreacion}</h3>
                            <h3 className="atributoDato">{props.data?.titulo}</h3>
                        </div>
                    </div>

                    <div><hr className="hr-pedidos"></hr></div>
                    <div className="d-flex flex-row bd-highlight">
                        <div className="mb-3">
                            <div className="atributoTitle" style={{ fontWeight: "bold", marginRight: "1.25rem" }}>ESTADO:</div>
                        </div>
                        <div className="mb-3">
                            <div className="atributoDato" style={{ marginRight: "0.8rem" }}>{props.data?.nombre}</div>
                        </div>
                    </div> 
                    { props.data?.ncc ?
                    <>
                     <div className="d-flex flex-row bd-highlight">
                        <div className="mb-3">
                            <div className="atributoTitle" style={{ fontWeight: "bold", marginRight: "1.25rem" }}>RESOLUCIÓN NCC:</div>
                        </div>
                        <div className="mb-3">
                            <div className="atributoDato" style={{ marginRight: "0.8rem" }}>{props.data?.ncc}</div>
                        </div>
                    </div>   
                    </>
                    :
                    "" 
                    }
                     { props.data?.tipoDocumento ?
                    <>
                    <div className="d-flex flex-row bd-highlight">
                        <div className="mb-3">
                            <div className="atributoTitle" style={{ fontWeight: "bold", marginRight: "1.25rem" }}>{(props.data?.tipoDocumento)?.toUpperCase()}:</div>
                        </div>
                        <div className="mb-3">
                            <div className="atributoDato" style={{ marginRight: "0.8rem" }}>{props.data?.nroDocumento}</div>
                        </div>
                    </div>   
                    </>
                    :
                    "" 
                    }
                    <TimelineComponent data={props.data?.orden}/>             
                    <Button
                        className="my-3"
                        style={{ backgroundColor: colores.maincolor, color: '#fff' }}
                        variant="contained"
                        onClick={() => handleClick()}
                        type="button"
                    >
                        VER DETALLES
                    </Button>
                    
                    { showDetalle ?
                    <>
                        <div><hr className="hr-pedidos"></hr></div>
                        <div className="d-flex flex-row bd-highlight  justify-content-between">
                            <div className="mb-5">
                                <div className="atributoTitle" style={{ fontWeight: "bold", marginRight: "1.25rem" }}>DETALLE</div>
                                <div className="d-flex flex-row bd-highlight  justify-content-between">
                                <h3 className="atributoDato">{props.data?.detalle}</h3>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    "" 
                    }
                </div>
            </div>
        </>
    )
}
export default connect()(Reclamo);